/** @format */

const education = [
  {
    path: '/education-admin',
    name: 'education-admin',
    meta: { auth: 1, title: 'Система обучения | Админка' },
    component: () => {
      return import('@/views/study/admin.vue');
    },
  },
  {
    path: '/education-admin/:id',

    name: 'education-chain-view',
    meta: { auth: 1, title: 'Система обучения | Урок' },
    component: () => {
      return import('@/views/study/viewChain.vue');
    },
  },
  {
    path: '/education-person',
    name: 'education-person',
    meta: { auth: 1, title: 'Система обучения | Мой курс', education: true },
    component: () => {
      return import('@/views/study/client.vue');
    },
  },
  {
    path: '/education-person/:id',
    name: 'education-person-lesson',
    meta: { auth: 1, title: 'Система обучения | Урок', education: true },
    component: () => {
      return import('@/views/study/clientLesson.vue');
    },
  },
];

export default education;
