<template>
  <div ref="table" class="f-table-wrapper">
    <context-menu ref="headerContextMenu" :items="[{ name: 'settings', title: 'Настройка таблицы' }]" @click="onContextMenuClick($event)" />
    <a-table-f-settings ref="settings" :fields="model" :table="paramName" />
    <div class="f-table" :style="{ minWidth: calcMinWidth }" @mouseup.stop="onSeparatorMouseup()" @mousemove.stop="onSeparatorMousemove($event)">
      <slot name="loader">
        <a-loader v-if="loading" />
      </slot>
      <slot name="header">
        <div class="f-table-header">
          <div class="f-table-row" :class="{ head2rows: headRows == 2 }" @contextmenu="$refs.headerContextMenu.showMenu($event, model)">
            <template v-for="(field, i) in modelVisible">
              <template v-if="field.hidden" />
              <template v-else>
                <a-table-f-col
                  :key="i"
                  :sort="sort"
                  :class="{ head2rows: headRows == 2 }"
                  :sortable="field.sortable"
                  :width="filedsWidth[i]"
                  :color="filedsColor[i]"
                  @sort="onClickHeader($event, field.sortable)"
                  :field="field.name ? field.name : ''"
                >
                  <span v-if="field.name == 'checked'" style="margin-top: -10px; margin-bottom: -22px">
                    <v-checkbox dense v-model="checkAll" />
                  </span>
                  <span v-else @click="onClickHeader(field.name, field.sortable)">
                    {{ field.title }}
                  </span>
                  <div class="f-table-separator" @mousedown.stop="onSeparatorMousedown($event, i)"></div>
                </a-table-f-col>
              </template>
            </template>
          </div>
          <div v-if="searchable" class="f-table-row" :class="{ searchable: searchable }">
            <template v-for="(field, i) in modelVisible">
              <template v-if="field.hidden" />
              <template v-else>
                <a-table-f-search :key="i" :model="field" :width="filedsWidth[i]" :color="filedsColor[i]" :values="filters" @input="onSelectInput($event)">
                  <div class="f-table-separator" @mousedown.left.stop="onSeparatorMousedown($event, i)"></div>
                </a-table-f-search>
              </template>
            </template>
          </div>
        </div>
      </slot>
      <slot>
        <div
          class="f-table-body"
          :style="getStyleBody"
          :class="{
            head2rows: headRows == 2,
            searchable: searchable,
            footerpresent: !footerHide,
          }"
        >
          <div class="f-table-row f-table-background">
            <template v-for="(field, i) in modelVisible">
              <template v-if="field.hidden" />
              <template v-else>
                <div class="f-table-col" :style="{ width: filedsWidth[i] + 'px' }" :key="i">
                  <div class="f-table-separator" @mousedown.left.stop="onSeparatorMousedown($event, i)"></div>
                </div>
              </template>
            </template>
          </div>
          <slot name="top" />
        </div>
        <div ref="body" class="f-table-body" :style="getStyleBody" :class="{ head2rows: headRows == 2, searchable: searchable, footerpresent: !footerHide }">
          <div
            v-for="(row, key) in data"
            :key="key"
            class="f-table-row f-table-row-body"
            :class="{
              selected: checkSelected(row),
              notviewed: row.viewedon !== undefined && !row.viewedon && row.createdby_id !== $root.profile.id,
            }"
          >
            <template v-for="(field, i) in modelVisible">
              <template v-if="field.hidden" />
              <template v-else>
                <v-hover v-slot="{ hover }" open-delay="500">
                  <div
                    class="f-table-col d-flex"
                    :class="{ 'pa-0': field.type == 'image' }"
                    :key="i"
                    :style="{ width: filedsWidth[i] + 'px', 'background-color': field.is_color && row._row && row._row.field }"
                    @mousedown.left.stop="$emit('click', { row: row, field: field, event: $event, key })"
                    @contextmenu="$emit('rowContextMenu', { row: row, field: field, event: $event, key })"
                  >
                    <div v-if="(hover || !true) && field.editable" class="f-table-edit">
                      <v-btn icon @click.stop="$emit('onEditField', { row, field })">
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </div>
                    <slot :name="'item.' + field.name" :field="field" :item="getValue(row, field.name)" :row="row">
                      <component
                        v-if="editItem && (editItem.row || {}) == row && field.typeEdit && componentExist(field.typeEdit)"
                        :is="getComponentName(field.typeEdit)"
                        :model="field"
                        :value="row[field.name]"
                        :values="row"
                      />
                      <component
                        v-if="field.view && componentExist(field.view)"
                        :is="getComponentName(field.view)"
                        :model="field"
                        :value="row[field.name]"
                        :values="row"
                      />
                      <component
                        v-else-if="field.type && componentExist(field.type)"
                        :is="getComponentName(field.type)"
                        :model="field"
                        :value="getValue(row, field.name)"
                        :values="row"
                      />
                      <div v-else-if="field.type == 'checkbox'">
                        <slot name="item.checkbox" v-bind:item="row">
                          <v-checkbox v-if="canCheck(row, field)" dense hide-details :value="row.checked" @click.prevent.left="clickCheckbox($event, row, key, field)" />
                        </slot>
                      </div>
                      <div v-else-if="field.type == 'folder'">
                        <slot name="item.folder" v-bind:item="row">
                          <v-icon v-if="row.isparent || (field.name && row[field?.name] && row.id == folder_id)">fas fa-level-up-alt</v-icon>
                          <v-icon v-else-if="row.isparent || (field.name && row[field?.name])">far fa-folder</v-icon>
                          <v-icon v-else>far fa-file</v-icon>
                        </slot>
                      </div>
                      <div v-else-if="field.name == 'actions'">
                        <slot name="item.actions" v-bind:item="row" />
                      </div>
                      <div v-else>{{ row[field.name] }}</div>
                    </slot>

                    <div class="f-table-separator" @mousedown.left.stop="onSeparatorMousedown($event, i)"></div>
                  </div>
                </v-hover>
              </template>
            </template>
          </div>
        </div>
      </slot>
      <slot name="footer">
        <div v-if="footer" class="f-table-footer">
          <div class="f-table-row">
            <a-table-f-col v-for="(el, key) in footer" :key="key" :width="el.width" v-html="el.value"></a-table-f-col>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import { components } from "../../../mixings";
let minWidth = 40;

export default {
  mixins: [components],
  props: {
    data: Array,
    model: [Object, Array],
    folder_id: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
    sort: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    pager: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      },
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    paramName: {
      type: String,
      default: "",
    },
    editItem: {
      type: Object,
      default: () => {},
    },
    footer: {
      type: Object,
      default: () => {},
    },
    footerHide: {
      type: Boolean,
      default: false,
    },
    selectedRows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selected: {
      type: Object,
      default: () => {
        return { text: "", value: 0 };
      },
    },
    headRows: { type: Number, default: 1 },
  },
  data() {
    return {
      lastCheckClick: null,
      tableHeight: 0,
      filedsWidth: [],
      filedsColor: [],
      checkAll: false,
      mousedown: { pressBtn: false, rowId: null, pos: null, currPos: null },
      timeoutID: null,
    };
  },
  watch: {
    model() {
      this.$root.makedirs(this.model);
      this.setWidth();
      this.setColor();
    },
    checkAll(v) {
      this.$emit("checkedAll", v);
    },
  },
  created() {},
  mounted() {
    document.addEventListener("scroll", this.onScroll, true);
    window.addEventListener("resize", this.onResize, true);
    this.setWidth();
    this.setColor();
    this.onResize();
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll, true);
    window.removeEventListener("resize", this.onResize, true);
  },
  computed: {
    modelVisible() {
      let model = [];
      if (this.model)
        this.model.forEach((el) => {
          if (!this.isHiddenField(el)) model.push(el);
        });
      return model;
    },
    getStyleBody() {
      let res = this.style_body;
      if (this.footer) {
        //console.log("have footer");
        res.bottom = "30px";
      } else {
        //console.log("havent footer");
      }

      return res;
    },
    calcMinWidth() {
      let w = 0;
      let add = 0;
      for (let el of this.filedsWidth) {
        w = w + parseFloat(el);
        add = add + 3;
      }
      return add + w + 11 + "px";
    },
    headers() {
      let res = [];
      for (let i = 0; i < this.model.length; i++) {
        res.push({ text: this.model[i].title, value: this.model[i].name });
      }
      res.push({ text: "Actions", value: "actions", sortable: false });
      return res;
    },
  },
  methods: {
    getRowStyle(row) {
      let style = "";
      style += !row.viewedon ? "font-weight: bold;" : "";
      return style;
    },
    checkSelected(row) {
      let key;
      if (row.id) key = row.id;
      else if (this.model.find((m) => m.isKey)) {
        key = row[this.model.find((m) => m.isKey).name];
      }

      return this.selected.value == key || this.selectedRows.includes(key);
    },
    getValue(row, name) {
      let res = row;
      name.split(".").forEach((n) => {
        res = res?.[n];
      });
      return res;
    },
    isHiddenField(f) {
      if (f?.hidden) return true;
      if (this.$store.getters["config/getLocalParams"]?.[this.paramName + ".FieldHidden." + f.name]) return true;
      return false;
    },
    onContextMenuClick(e) {
      if (e.name == "settings") {
        this.$refs.settings.show();
      }
    },
    canCheck(r, f) {
      let res = true;
      if (f?.isDisableFunc) {
        res = f.isDisableFunc(r);
        r.check_disable = res ? 0 : 1;
      }
      if (r?.check_disable == 1) res = false;
      return res;
    },
    clickCheckbox(event, row, key, field) {
      /// if (!key) return;
      if (event.shiftKey) {
        let start = this.lastCheckClick;
        let end = key;
        let dif = end - start;
        if (dif < 0) {
          dif = -dif;
          end = start;
          start = key;
        }
        for (let i = 0; i <= dif; i++) {
          let p = start + i;
          let a = this.data[p];
          if (this.canCheck(row, field)) a.checked = true;
          else a.checked = false;
          /* if (a?.check_disable == 1) a.checked = false;
          else if (field?.isDisableFunc && field.isDisableFunc(row) === false) a.checked = false;
          else a.checked = true; */
          this.$set(this.data, p, a);
        }
      } else {
        //console.log("clickCheckbox", key, row);
        this.lastCheckClick = key;
        if (this.canCheck(row, field)) row.checked = !row.checked;
        else row.checked = false;
        /*
        if (row?.check_disable == 1) row.checked = false;
        else if (field?.isDisableFunc && field.isDisableFunc(row) === false) row.checked = false;
        else row.checked = !row.checked; */
      }
      this.$emit(
        "setCheckbox",
        this.data.filter((d) => d.checked == true)
      );
    },
    onClickHeader(field, sortable = false) {
      if (field && field != "checked") {
        if (sortable) this.$emit("sort", field);
      }
    },
    onSelectInput(e) {
      Object.assign(this.filters, e);
      this.$emit("change-filter", this.filters);
    },
    getElArrayByValue(array, field, name) {
      return array.find((x) => x[field] == name);
    },
    getModelByName(name) {
      return this.getElArrayByValue(this.model, "name", name);
    },
    setWidth() {
      this.filedsWidth = [];
      // let hashModel = JSON.stringify(this.model).md5();
      for (let i = 0; i < this.modelVisible.length; i++) {
        let width;
        if (this.modelVisible[i].hidden) width = 0;
        else if (this.modelVisible[i].width) width = parseFloat(this.modelVisible[i].width);
        else width = 100;
        if (this.paramName) {
          width = this.$store.getters["config/getLocalParams"]?.[this.paramName + ".FieldWidth." + this.modelVisible[i].name] || width;
        }
        this.$set(this.filedsWidth, i, width);
      }
    },
    setColor() {
      this.filedsColor = [];
      // let hashModel = JSON.stringify(this.model).md5();
      for (let i = 0; i < this.modelVisible.length; i++) {
        let color = this.modelVisible[i]?.color || null;
        if (this.paramName) {
          color = this.$store.getters["config/getLocalParams"]?.[this.paramName + ".FieldColor." + this.modelVisible[i].name] || color;
        }
        this.$set(this.filedsColor, i, color);
      }
    },
    onSeparatorMousedown(e, id) {
      if (!this.mousedown.pressBtn) {
        this.mousedown.pressBtn = true;
        this.mousedown.rowId = id;
        this.mousedown.pos = e.clientX;
        this.mousedown.currPos = this.filedsWidth[id];
        document.body.style["user-select"] = "none";
        // if (window.localStorage.getItem("leftSizePozition")) {
        //   this.$root.leftSizePozition = parseInt(
        //     window.localStorage.getItem("leftSizePozition")
        //   );
        // }
      }
    },
    onSeparatorMouseup: function () {
      if (this.mousedown.pressBtn) {
        this.mousedown.pressBtn = false;
        this.mousedown.rowId = null;
        document.body.style["user-select"] = "auto";
      }
    },
    saveColumnWidth(c, w) {
      if (this.paramName) {
        let col = this.paramName + ".FieldWidth." + c;
        clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(() => {
          this.$store.commit("config/setLocalParams", {
            name: col,
            value: w,
          });
        }, 1000);
      }
    },
    onSeparatorMousemove: function (e) {
      if (this.mousedown.pressBtn) {
        let pos = this.mousedown.pos - e.clientX;
        let wid = this.mousedown.currPos - pos;
        if (wid < minWidth) {
          wid = minWidth;
        }
        // this.filedsWidth[this.mousedown.rowId] = this.filedsWidth[this.mousedown.rowId] - pos
        this.$set(this.filedsWidth, this.mousedown.rowId, wid);
        let col = this.modelVisible[this.mousedown.rowId].name;

        this.saveColumnWidth(col, wid);
      }
    },
    onScroll(e) {
      let el = this.$refs.body;
      let offset = 200;
      if (e.target == el) {
        if (el.scrollHeight - el.offsetHeight <= el.scrollTop + offset) {
          this.$emit("scrollEnd", e);
        }
      }
    },
    onResize() {
      let height = window.innerHeight;
      let top = this.$refs.table.getBoundingClientRect().top;
      this.tableHeight = height - top - 100;
    },
  },
};
</script>

<style lang="scss">
@mixin table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover) {
  .f-table-wrapper {
    position: relative;
    height: 100%;
    overflow: auto;
    border: 1px solid $borderColor;
  }
  .f-table {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    box-sizing: border-box;
    background-color: $backgroundColor;
  }
  .f-table-header {
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    .f-table-row {
      min-height: 20px;
    }
  }

  .f-table-row {
    display: flex;
    flex-flow: row wrap;
    min-height: 40px;
    &.head2rows {
      height: 42px !important;
    }
    border-right: 1px solid $borderColor;
    &.searchable {
      border-top: 1px solid $borderColor;
      height: 30px;
    }
  }
  .f-table-body {
    position: absolute;
    overflow-x: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    &.footerpresent {
      bottom: 30px;
    }
    top: 28px !important;
    &.head2rows {
      top: 44px !important;
    }
    &.searchable {
      top: 58px !important;
      &.head2rows {
        top: 74px !important;
      }
    }

    .f-table-col {
      cursor: pointer;
    }
    .f-table-row.f-table-row-body {
      border-bottom: 1px solid $borderColor;
      background-color: $backgroundColor;
      &:hover {
        background: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
    .f-table-row.f-table-row-body:nth-child(odd) {
      background-color: $backgroundColorSecond;
      &:hover {
        background-color: $backgroundColorHover;
      }
      &.selected {
        background-color: $backgroundColorSelected;
      }
      &.notviewed {
        font-weight: bold;
      }
    }
  }
  .f-table-background {
    height: 100% !important;
    //   background: #fff !important;
  }
  .f-table-col {
    padding: 4px 4px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    border-left: 1px solid $borderColor;
    color: $color;
    &.sortable {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
    &.head2rows > span {
      hyphens: auto !important;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: vertical;
      line-clamp: 2 !important;
      box-orient: vertical;
    }
  }
  .f-table-separator {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    right: 0;
    z-index: 1;
    margin: 0;
    border: none;
    cursor: col-resize;
  }
  .f-table-edit {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: 0;
    border: none;
    cursor: col-resize;
  }
  .f-table-footer {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    border-top: 1px solid #d9d9d9;
    background: #eee;
    font-weight: bold;
  }
}

.theme--dark {
  $color: #bdbdbd;
  $borderColor: #808080;
  $backgroundColor: #161616;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: lighten($backgroundColor, 3%);
  $backgroundColorHover: lighten($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
.theme--light {
  $color: #1a1a1a;
  $borderColor: #a3a3a3;
  $backgroundColor: #ffffff;
  $backgroundColorSelected: hsl(60, 75%, 81%);
  $backgroundColorSecond: darken($backgroundColor, 5%);
  $backgroundColorHover: darken($backgroundColor, 10%);
  @include table-style($color, $borderColor, $backgroundColor, $backgroundColorSelected, $backgroundColorSecond, $backgroundColorHover);
}
</style>
