<template>
  <div class="d-flex" style="position: relative">
    <v-autocomplete
      v-if="model.autocomplete"
      outlined
      :items="sortOptions"
      :item-text="textField"
      item-value="value"
      :item-disabled="checkDisabled"
      menu-props="offset-y, auto, overflowY"
      :value="v"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :clearable="!readonly"
      :readonly="readonly"
      :dense="config.dense || false"
      @input="$emit('input', $event)"
      @keydown="onKeyDown"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips || false"
      :hide-selected="model.chips || false"
    ></v-autocomplete>
    <v-select
      v-else
      outlined
      :clearable="!readonly"
      :items="sortOptions"
      :item-text="textField"
      item-value="value"
      :item-disabled="checkDisabled"
      :value="v"
      menu-props="offset-y, auto, overflowY"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :readonly="readonly"
      :dense="config.dense || false"
      @input="onInput($event)"
      @keydown="onKeyDown"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips || false"
      :hide-selected="model.chips || false"
    >
    </v-select>
    <btn-rating v-if="(model.rating && config.rating) || false" v-model="rating"> </btn-rating>
  </div>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";

/*
<!--       @focus="toggleSelect = true"
      @blur="toggleSelect = false"
      @click="toggleSelect = true"
      :menu-props="{
        value: toggleSelect,
        'offset-y': true,
        closeOnContentClick: true,
      }" -->

*/
export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Array],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },

    model: Object,
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {}, disabled: false },
  },
  data() {
    return {
      cc: 0,
      showAllItem: false,
      title: null,
      preText: null,
      toggleSelect: false,
    };
  },
  computed: {
    v() {
      let res = this.value;
      if (this.model.chips) {
        if (res === null || res === undefined) res = [];
        if (!Array.isArray(res))
          try {
            res = res.split(",");
          } catch (error) {
            res = [res];
          }
      }
      return res;
    },
    textField() {
      return this.model.textField || "text";
    },
    options() {
      let res = this.getOptions(this.model) || [];

      return res;
    },
    sortOptions() {
      let model = this.model;
      let list = JSON.parse(JSON.stringify(this.options));
      if (model?.dirVar) {
        const key = model.dirVar.key;
        if (this.values) {
          const v = model?.json ? this.values?.data?.[key] : this.values[key];
          const name = model.dirVar.dirName.find((el) => {
            return el.value == v;
          });
          this.title = name?.title || null;
          this.preText = name?.preText || null;
        }
      }
      if (this.value && !list.some((list) => list.value === this.value) && !Array.isArray(this.value)) {
        list = [{ text: this.value, value: this.value }, ...list];
      }
      return list.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    titleValue() {
      return this.title || this.model.title;
    },
    rating: {
      get() {
        let res = this.values.data?.rating?.[this.model.name] || "";
        return res;
      },
      set(v) {
        this.$emit("rating", { name: this.model.name, value: v });
      },
    },
  },
  methods: {
    onKeyDown(e) {
      if (e.key === "*") {
        this.showAllItem = !this.showAllItem;
        e.preventDefault();
      }
    },
    checkDisabled(e) {
      if (this.showAllItem) return false;
      if (e?.isActive === false || e.hidden) return true;
      return false;
    },
    onInput(e) {
      this.$emit("input", e);
      if (this.model.cascade) {
        for (const key in this.model.cascade) {
          const el = this.model.cascade[key];
          if (el.type == "field") {
            let value = this.options.find((f) => f["value"] == e)?.[el.value];
            if (value !== undefined) {
              this.$emit("cascade", { field: el.value, value });
            }
          }
          if (el.type == "formula") {
            let func;
            let f = this.options.find((f) => f["value"] == e)?.[el.value];
            if (typeof f == "string") func = eval(f);
            let value = func(this.values);
            if (value !== undefined) {
              this.$emit("cascade", { field: key, value });
            }
          }
        }
      }
    },
    getSortOptions1(model) {
      let list = JSON.parse(JSON.stringify(this.options));
      if (model?.dirVar) {
        const key = model.dirVar.key;
        if (this.values) {
          const v = model?.json ? this.values?.data?.[key] : this.values[key];
          const name = model.dirVar.dirName.find((el) => {
            return el.value == v;
          });
          this.title = name?.title || null;
          this.preText = name?.preText || null;
        }
      }
      if (this.value && !list.some((list) => list.value === this.value) && !Array.isArray(this.value)) {
        list = [{ text: this.value, value: this.value }, ...list];
      }
      return list.sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
    },
  },
};
</script>
<style lang="scss">
.v-list-item.v-list-item--disabled {
  display: none !important;
}
</style>
