/** @format */

export default {
  created() {},
  destroyed() {},
  computed: {
    canStatus() {
      return !true;
    },
    statuses() {
      let res = this.m.statuses;
      if (typeof res == 'string' && res.includes('config.')) {
        res = this.getFromConfig(res);
      }
      return res;
    },
    statusItems() {
      //if (!this.data.status && this.data.status !== 0) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter((s) => {
          return arr.includes(s.value) || s?.mask == '*';
        });
      }
      if (this.m.accessName) {
        //res = res.filter((s) => this.getAccess((this.m.isDocument === false ? '' : 'documents.') + this.m.accessName + '.status.' + s.value, null, true));
        res.forEach((s) => {
          if (!this.getAccess((this.m.isDocument === false ? '' : 'documents.') + this.m.accessName + '.status.' + s.value, null, true)) s.disabled = true;
        });
      }
      items = JSON.parse(JSON.stringify(res));

      return items;
    },

    statusCurrent() {
      let statusCur = this.statuses.find((el) => el.value == (this.data.status || 0));
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  methods: {
    getFromConfig(p) {
      let tmp;
      const path = p.split('.');
      for (let i = 0; i < path.length; i++) {
        const el = path[i];
        if (i === 0) {
          tmp = JSON.parse(JSON.stringify(this.$store.getters['config/get'] || {}));
        }
        tmp = JSON.parse(JSON.stringify(tmp?.[el] || {}));
      }
      return tmp;
    },
    async clickStatus(status) {
      let ok = false;
      ok = await this.$refs.confirmDialog.show({
        title: 'Изменение статуса',
        message: [`Вы действительно хотите поменять `, ` статус на [${status.text}]?`],
        okButton: 'Да',
        cancelButton: 'Нет',
      });

      if (!ok) return;
      if (status.func) {
        this[status.func](status.value);
      } else {
        this.changeStatus(status);
      }
      this.fitchData();
    },
    async beforeChangeStatus(status) {
      return true;
    },
    async changeStatus(status) {
      const result = await this.beforeChangeStatus(status);
      console.log('changeStatus', result);
      if (!result) return;
      let ok = false;
      if (!this.api || !this.data.id) return;
      this.loading = true;
      let val = status?.setValue !== undefined ? status.setValue : status.value;
      let data = { id: this.id, status: val };
      if (typeof result === 'object' && result.data) data = Object.assign(data, result.data);
      let response = await this.$axios.post(this.api, data);
      this.loading = false;
      //let status = response.data.status == 'ok';
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit('show-info', {
          text: 'Статус изменен',
        });
      }
    },
  },
};
