<template>
  <div class="menu__sticky">
    <v-list nav dense>
      <template v-for="(el, i) in value">
        <template v-if="el.auth <= role || isVisible(el) || true">
          <v-list-group v-if="el.is_parent" :key="i" :value="false" :prepend-icon="el.icon">
            <template v-slot:activator>
              <v-list-item-title style="font-size: 1rem; font-weight: 300">
                {{ $t(el.name) }}
              </v-list-item-title>
            </template>
            <list-main-menu-item v-if="e.auth <= role || isVisible(e)" v-for="(e, i) in el.sublist" :key="i" :value="e" :sublist="true" />
          </v-list-group>
          <list-main-menu-add-menu v-else-if="el.menu" :el="el" :key="i" />
          <list-main-menu-item v-else :key="i" :value="el" />
        </template>
      </template>
    </v-list>
    <div v-if="!loading">
      <v-expansion-panels v-if="$root.profile.role === 1000" focusable flat tile>
        <v-expansion-panel v-for="(item, i) in data" :key="i" class="px-0 ma-0">
          <v-expansion-panel-header color="rgb(89, 89, 89)" class="px-0 pl-4">{{ item.name }}</v-expansion-panel-header>
          <v-expansion-panel-content color="rgb(89, 89, 89)" class="px-0 pl-4">
            <list-edu-menu-item :key="i" :value="item" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else v-for="(item, i) in data" :key="i" class="pa-0">
        <list-edu-menu-item :key="i" :value="item" class="px-2 pl-4 py-0" />
      </div>
    </div>
  </div>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],
  props: {
    value: Array,
    role: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      data: [],
      m: this.$store.getters["config/get"].models.education.chain,
      loading: true,
    };
  },
  computed: {},
  mounted() {
    this.$root.$on("websocket", this.registerWS);
    //this.$root.$on("set_viewed_wa", this.registerSetViewed);
  },
  destoyed() {
    this.$root.$off("websocket", this.registerWS);
  },
  created() {
    this.getCourse();
  },
  methods: {
    registerWS(data) {
      console.log("ddddddddddddd", data);
      if (data && data.event == "notify_socket" && data?.data?.name == "completeEducation") {
        this.getCourse();
      }
    },
    async getCourse() {
      this.loading = !false;
      console.log("getCourse");
      let params = { filters: { parent_id: 0 } };
      const d = await this.$axios.get(this.m.api, { params });
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.loading = false;
    },
    isVisible(el) {
      let res = this.getAccess("menu." + (el?.route?.name || el?.accessName || ""));
      return res;
    },
  },
};
</script>
<style lang="scss">
@import "@/style/education.scss";
</style>
