import { render, staticRenderFns } from "./base.vue?vue&type=template&id=27a73ede&"
import script from "./base.vue?vue&type=script&lang=js&"
export * from "./base.vue?vue&type=script&lang=js&"
import style0 from "./base.vue?vue&type=style&index=0&id=27a73ede&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCheckbox,VHover,VIcon})
