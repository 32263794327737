<template>
  <div class="pa-2">
    <v-card v-if="!loaded" style="border-radius: 8px" :class="{ 'pb-0 ': !$vuetify.breakpoint.smAndDown }">
      <a-loader />
      <v-card-title v-if="title" :class="{ 'pa-2 ': $vuetify.breakpoint.smAndDown, 'pb-2 pt-3': !$vuetify.breakpoint.smAndDown }">
        <span class="text_no_wrap" style="width: 100%" :class="{ 'pb-1 pl-1': $vuetify.breakpoint.smAndDown }">
          <v-skeleton-loader class="mx-auto" type="heading1, actions"></v-skeleton-loader>
        </span>
      </v-card-title>
      <v-card-text :style="{ height: contentHeight - 35 + 'px' }" style="overflow-y: auto; overflow-x: hidden" :class="{ 'py-2 px-2': $vuetify.breakpoint.smAndDown }">
        <v-row>
          <v-col cols="12" sm="6" class="px-2">
            <v-skeleton-loader class1="mx-auto" :height="(contentHeight - 40) / 2 - 120" type="image"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="6" class="px-2">
            <v-skeleton-loader class="mx-auto" :height="(contentHeight - 40) / 2 - 120" type="image"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" class="px-2">
            <v-skeleton-loader class="mx-auto" :height="(contentHeight - 40) / 2 + 80" type="image"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else style="border-radius: 8px" :class="{ 'pb-0 ': !$vuetify.breakpoint.smAndDown }">
      <span v-if="header" class="text_no_wrap" :class="{ 'pb-1 pl-1': $vuetify.breakpoint.smAndDown }">
        <slot name="header" />
      </span>
      <v-card-title v-if="title" :class="{ 'pa-2 ': $vuetify.breakpoint.smAndDown, 'pb-2 pt-3': !$vuetify.breakpoint.smAndDown }">
        <span class="text_no_wrap" :class="{ 'pb-1 pl-1': $vuetify.breakpoint.smAndDown }">
          <slot name="title" />
          <v-spacer />
        </span>
        <v-spacer />
        <div class="text_no_wrap fullscreen_card__actions" :class="{ 'mt-1': !$vuetify.breakpoint.smAndDown }">
          <slot name="actions"></slot>
        </div>
      </v-card-title>
      <v-card-text :style="{ height: contentHeight + 'px' }" style="overflow-y: auto; overflow-x: hidden" :class="{ 'py-2 px-2': $vuetify.breakpoint.smAndDown }">
        <slot></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script> 
import { staticFullHeight } from "./mixings";

export default {
  mixins: [staticFullHeight],
  props: { title: { type: Boolean, default: true }, header: { type: Boolean, default: false }, loaded: { type: Boolean, default: true } },
  data: () => ({
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
  }),
};
</script>

<style lang="scss">
//$skeleton-loader-image-height= 300
.v-skeleton-loader__image {
  height: 100% !important;
}
.text_no_wrap {
  overflow: auto;
  white-space: nowrap;
}
.fullscreen_card__actions {
  .v-btn {
    margin-left: 5px;
  }
}
</style>
