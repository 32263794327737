<template>
  <div class="d-flex" style="position: relative">
    <v-autocomplete
      color="red"
      outlined
      :clearable="!readonly"
      :items="data"
      :item-text="textField"
      :item-value="valueField"
      :item-disabled="checkDisabled"
      :value="value"
      :label="$t(titleValue)"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      :readonly="readonly"
      :prefix="preText"
      :no-data-text="'Нет вариантов для выбора'"
      :small-chips="model.chips || false"
      :multiple="model.chips || false"
      :deletable-chips="model.chips || false"
      :hide-selected="model.chips || false"
      @input="
        $emit('input', $event);
        sendData($event);
      "
      class="list-hide-disabled"
    ></v-autocomplete>
    <btn-rating v-if="(model.rating && config.rating) || false"> </btn-rating>
  </div>
</template>

<script>
import modelOptions from "./../../libs/modelOptions";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number, Object],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      data: [],
      title: null,
      preText: null,
      showAllItem: false,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    value(v) {
      if (v) this.fetchData();
    },
    "model.filters"(v) {
      //console.log("model.filters");
      this.fetchData();
    },
  },
  computed: {
    titleValue() {
      return this.title || this.model.title;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    textField() {
      return this.model?.itemField?.text || "text";
    },
    valueField() {
      return this.model?.itemField?.value || "value";
    },
  },
  methods: {
    onKeyDown(e) {
      if (e.key === "*") {
        this.showAllItem = !this.showAllItem;
        e.preventDefault();
      }
    },
    checkDisabled(e) {
      if (this.showAllItem) return false;
      if (!this.model.isActive) return false;
      if (e?.isActive === false || e.hidden) return true;
      return false;
    },
    getApi(model) {
      let api = "";
      if (model?.apiVar) {
        const key = model.apiVar.key;
        if (this.values) {
          const v = model?.json ? this.values?.data?.[key] : this.values[key];

          const name = model.apiVar.apiName.find((el) => {
            return el.value == v;
          });
          let idField = model.apiVar?.idField || null;

          api = name?.name || "";
          this.title = name?.title || null;
          this.preText = name?.preText || null;
        }
        return api;
      }
      return model.api;
    },

    async fetchData() {
      let api = await this.getApi(this.model);
      if (!api) return;
      let type = "get";
      let data = {};
      if (typeof api === "object") {
        type = api.type || type;
        if (api.data) {
          data.name = api.data?.name;
          data.params = {};
          for (const key in api.data.params) {
            if (typeof api.data.params[key] === "string" && api.data.params[key].includes("function(d)")) {
              let func = eval(api.data.params[key]);
              data.params[key] = func(this.values) || 0;
            } else data.params[key] = api.data.params[key];
          }
        }

        //data = api.data || null;
        api = api.api;
      }

      if (!api) return;

      let params = {};
      if (this.model.sort) {
        params.sort = this.model.sort;
      }
      if (this.model.filters) {
        if (this.model.filters.formula) {
          let res = eval(this.model.filters.formula || "{}");
          if (typeof res === "function") res = res(this.values);
          params.filters = res;
        } else params.filters = this.model.filters;
      }
      if (this.model?.where) {
        params.where = this.model.where;
      }
      this.loading = true;
      if (type !== "get") {
        //console.log(data);
      }
      let response = type == "get" ? await this.$axios.get(api, { params }) : await this.$axios.post(api, data);
      this.loading = false;
      this.data = this.filterItems(response.data.data).multiSort(["text"]);
      if (this.data.length == 1) {
        if (!this.value && this.values && !this.values?.id) {
          this.$emit("input", this.data[0][this.valueField]);
          //this.sendData(this.data[0][this.valueField]);
        }
      }
    },
    sendData(v) {
      let selected = this.data.find((d) => d?.[this.valueField] == v);
      if (selected) {
        this.$emit("fieldData", selected);
      }
      this.$emit("selected", selected);
    },
  },
};
</script>
<style lang="scss">
.v-list-item.v-list-item--disabled11 {
  display: none !important;
}
</style>
