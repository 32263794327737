/** @format */
const hidden = true;
const editable = true;
const lampObjects = {
  types: [
    { value: 2, text: 'Cайт' },
    { value: 3, text: 'Авито' },
    { value: 4, text: 'Циан' },
  ],
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'source', title: 'Источник', type: 'string', sortable: true, width: 200, hidden },
    { name: 'name', title: 'Название', type: 'string', style: 'white-space: normal;', sortable: true, width: 200 },
    { name: 'city', title: 'Город', type: 'string', sortable: true, width: 100 },
    { name: 'address', title: 'Адрес', type: 'string', sortable: true, width: 300 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_object', sortable: true, width: 100 },
    { name: 'type', title: 'Тип', type: 'select', sortable: true, width: 100, options: 'objectTypes' },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses', sortable: true, width: 100 },
    { name: 'ap_area', title: 'Площадь', type: 'string', sortable: true, width: 65 },
    { name: 'ap_rooms', title: 'Комнат', type: 'string', sortable: true, width: 65 },
    { name: 'ap_ceiling_height', title: 'Потолки', type: 'string', sortable: true, width: 65 },
    { name: 'status_site', title: 'Сайт', type: 'selectbit', sourceList: 'types', sortable: true, width: 100 },
    { name: 'actions', title: 'Действия', width: 100 },
  ],
  form: [
    { name: 'name', title: 'Название', type: 'string', autofocus: true },
    { name: 'source', title: 'Источник', type: 'comboboxapi', api: '/mechti/agent_objects/tags/source', chips: false, validator: ['req'] },
    { name: 'city', title: 'Город', validator: ['req'], type: 'comboboxapi', api: '/mechti/agent_objects/tags/city', chips: false, rating: true },
    { name: 'address', title: 'Адрес', validator: ['req'], type: 'string', rating: true },
    { name: 'class', title: 'Класс', type: 'select', options: 'objectClasses', chips: false, rating: true },
    { name: 'type', title: 'Тип', type: 'select', options: 'objectTypes', validator: ['req'], rating: true },
    { name: 'ap_price_buy', title: 'Стоимость квартиры', validator: ['req'], type: 'number', rating: true },
    { name: 'ap_area', title: 'Площадь', type: 'number', validator: ['req'], rating: true },
    { name: 'ap_rooms', title: 'Количество комнат', type: 'number', rating: true },
    { name: 'ap_ceiling_height', title: 'Высота потолков', type: 'number', rating: true }, //9
    { name: 'ap_layout', title: 'Планировка', type: 'text', rating: true },
    { name: 'ap_level', title: 'Уровней', type: 'number', rating: true },
    { name: 'ap_floor', title: 'Этаж', type: 'number', rating: true },
    { name: 'ap_view', title: 'Вид из окна', type: 'text', rating: true },
    { name: 'infrastructure', title: 'Инфраструктура', type: 'comboboxapi', api: '/mechti/agent_objects/tags/infrastructure', chips: true, rating: true },
    { name: 'metro', title: 'Метро', type: 'comboboxapi', api: '/mechti/agent_objects/tags/metro', chips: true, rating: true }, //15
    { name: 'nearby', title: 'в 5 мин доступности', type: 'comboboxapi', api: '/mechti/agent_objects/tags/nearby', chips: true, default: [], rating: true },
    { name: 'description', title: 'Описание', type: 'text' },
    { name: 'photos', title: 'Галерея', type: 'images', reverce: true },
    { name: 'photos_design', title: 'Дизайн-проект', type: 'images' },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
    { name: 'doc', title: 'Проектная документация', type: 'filesdata', category: 1, hiddenCreate: true },
    { name: 'doc_adv', title: 'Рекламные материалы', type: 'filesdata', category: 2, hiddenCreate: true },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_object,status_site', default: 0 },
    { name: 'name', title: 'Название', default: '' },
    { name: 'rating' },
    { name: 'vendor', title: 'Продавец', from: 'data' },
    { name: 'is_investor', title: 'Тип участия', type: 'number', default: 0, validator: ['req'] },
    { name: 'credit_sum', title: 'Сумма ипотеки', type: 'number', size: 6 },
    { name: 'credit_rate' },
    { name: 'credit_date_start' },
    { name: 'credit_month_payment' },
    { name: 'plan_month_expenses', title: 'Ежемесячные расходы', type: 'number', size: 6 },
    { name: 'plan_date_finish', title: 'Дата готовности', type: 'datestring', size: 6 },
    { name: 'plan_sell_period', title: 'Сроки готовности (мес)', type: 'number', size: 6 },
    { name: 'plan_sell_price', title: 'Стоимость продажи', type: 'number', size: 6 },
    { name: 'plan_expenses', title: 'Стоимость реализации проекта', type: 'number', size: 6 },
    { name: 'plan_org_expenses', title: 'Плановые расходы на оформление', type: 'number', size: 6 },
    { name: 'plan_investment', title: 'Плановые личные вложения', type: 'number', readonly: true, size: 6 },
    { name: 'plan_percent_investor', title: '', type: 'number', size: 6 },
    { name: 'layout_legal', title: 'Планировка узаконена', type: 'switcher' },
    { name: 'ap_area_real', title: 'Площадь по договору', type: 'number', size: 6 },
    { name: 'ap_area_plan', title: 'Площадь фактическая', type: 'number', size: 6 },
    { name: 'residential_complex_id', title: 'ЖК', type: 'selectapi', api: 'mechti/residential_complex/select' },
    { name: 'residential_type', title: 'тип недвижимости', type: 'select', options: 'residential_type' },
  ],
};
export default lampObjects;
