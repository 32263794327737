/** @format */
import axios from '../../libs/axios';
import { models } from '@/config/models';
import config from '@/config/config';
const state = {
  version: { actual: 0, build: config.version, evaluted: false },

  data: {},
  phoneBar: false,
  params: null,
  typeClick: null,
  interfaceConfig: {},
  filters: {},
  dirs: {
    category: [],
    vendor: [],
    office: [],
    storage: [],
    object: [],
    service: [],
    cashbox: [],
    user: [],
    operationTable: [],
    employee: [],
    department: [],
    client: [],
    investor: [],
    good: [],
    objectProps: [],
    roles: [],
    metro: [],
  },
};

const getters = {
  getVersion: (state) => state.version,

  get: (state) => state.data,

  getDirs1_: (state) => {
    return state.dirs;
  },
  getAccess:
    (state) =>
    (name, conf = {}) => {
      const config = state.data.config;
      const o = name.split('.');
      let obj = name;
      let access = {};
      if (o.length == 1) {
        obj = o[0];
        access = config.access?.[obj] || {};
      } else if (o.length == 2) {
        obj = o[1];
        access = config.access?.[o[0]]?.[obj] || {};
      } else {
        return false;
      }
      console.log('getAccess', name, access);
      const role = this.$root.profile.role;
      const user = this.$root.profile.id;

      let res = false;
      if (access?.role?.length) {
        res = access.role.includes(role) || false;
        if (!res) res = access.role.includes(-1) && !access.role.includes(-role);
      }
      if (!res && access?.user?.length) res = res || access?.user.includes(user) || false;
      if (!res && access?.inRole?.length && conf?.access) {
        conf.access.forEach((el) => {
          res = res || (user == el.user_id && access.inRole.includes(el.user_role)) || false;
        });
      }
      if (!res && conf?.access && !access?.inRole?.length) {
        if (
          conf.access.find((el) => {
            return el.user_id == user;
          })
        ) {
          res = true;
          //console.log('conf?.access', conf, res);
        }
      }
      if (!res && conf?.users) {
        res = conf.users.includes(user) || false;
        //console.log('conf?.users', conf, res);
      }
      if (!res && conf?.data) {
        if (conf.data?.createdby_id == user) {
          res = true;
          //console.log('conf?.data', conf, res);
        }
      }

      //console.log('getAccess ' + name, { role, res }, access);

      return res;
    },
  getPhoneBar: (state) => state.phoneBar,
  getLocalParams: (state) => {
    let params = state.params;
    if (!params) {
      try {
        params = JSON.parse(localStorage.getItem('user-params'));
      } catch {}
      if (!params) {
        params = {};
        //localStorage.setItem('user-params', JSON.stringify(params));
      }
      state.params = params;
    }
    return state.params;
  },

  getSessionFilter: (state, name) => {
    let filters = state.filters;
    return filters;
  },
  getInterfaceConfig: (state) => {
    let interfaceConfig = state.interfaceConfig;
    if (!interfaceConfig) {
      try {
        interfaceConfig = state.data.config?.interface || {};
      } catch {}
      if (!interfaceConfig) {
        interfaceConfig = {};
        //localStorage.setItem('user-params', JSON.stringify(params));
      }
      state.interfaceConfig = interfaceConfig;
    }
    return state.interfaceConfig;
  },
  getTypeClick: (state) => {
    let type = state.typeClick;
    const def = 1;
    if (!type) {
      try {
        type = JSON.parse(localStorage.getItem('typeClick'));
        if (!type) type = state.data.config?.interface?.typeClick;
      } catch {}
      if (!type) {
        type = def;
        //localStorage.setItem('user-params', JSON.stringify(params));
      }
      state.typeClick = type;
    }
    return state.typeClick;
  },
};

const actions = {
  fitch: async ({ commit, dispatch }) => {
    let data = {};
    data.model = {};

    let enums = {
      destinations: [
        { value: 0, text: 'Исходящий' },
        { value: 1, text: 'Входящий' },
      ],
      callStatuses: [
        { value: 0, text: 'Не отвечен' },
        { value: 1, text: 'Отвечен' },
      ],
    };

    const resp = await axios.get('/config');
    const model = resp.data.config.models;
    //console.log('config 2', resp.data);
    const keys = Object.keys(resp.data.config);
    let m = {};
    for (const key in resp.data.config) {
      if (key.includes('models.')) {
        m[key.split('.')[1]] = Object.assign({}, resp.data.config[key]);
      }
    }

    resp.data.models = Object.assign({}, models, model, m);
    //console.log('/config 1', resp.data);

    commit('set', resp.data);

    //commit('set', { models, enums });
  },

  async fitchDirs_({ state, commit, dispatch }, data) {
    let dirs = state.dirs;
    if (data == 'roles' || !data) {
      let r = state.data.userRoles;
      let names = state.data.config.dictionary.roles;
      let roles = [];
      for (const key in r) {
        if (Object.hasOwnProperty.call(r, key)) {
          const id = parseInt(r[key]);
          let text = names[id] ? names[id] : key;
          const row = { id, value: id, text, origin: key };
          roles.push(row);
        }
      }
      dirs.roles = roles;
    }

    if (data == 'operationTable' || !data) {
      let name = 'operationTable';
      let data = [];
      let obj = state.data.accounting?.[name] || {};
      let operations = state.data.config.dictionary.operations;
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const el = obj[key];
          let text = operations[key] ? operations[key].name : el.name;

          const row = { id: parseInt(key), value: parseInt(key), text };
          for (const k in el) {
            row[k] = el[k];
          }
          data.push(row);
        }
      }
      dirs.operationTable = data;
    }
    if (data == 'office' || !data)
      axios.get('/accounting/dir/office/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.office = resp.data.data;
        }
      });
    if (data == 'storage' || !data)
      axios.get('/accounting/dir/storage/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.storage = resp.data.data;
        }
      });

    if (data == 'metro' || !data)
      axios.get('/mechti/underground_stations/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.metro = resp.data.data;
        }
      });
    if ((data == 'good' || !data) && false)
      axios.get('/accounting/dir/good/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.good = resp.data.data;
        }
      });
    if (data == 'employee' || !data)
      axios.get('/accounting/dir/employee/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.employee = resp.data.data;
        }
      });
    if (data == 'department' || !data)
      axios.get('/accounting/dir/department/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.department = resp.data.data;
        }
      });
    if (data == 'investor' || !data)
      axios.get('/mechti/investors/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.investor = resp.data.data;
        }
      });
    if (data == 'client' || !data)
      axios.get('/mechti/clients/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.client = resp.data.data;
        }
      });
    if (data == 'category' || !data)
      axios.get('/accounting/dir/service_category/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.category = resp.data.data;
        }
      });
    if (data == 'vendor' || !data) {
      if (!true)
        axios.get('/accounting/dir/vendor/select').then((resp) => {
          if (resp.data.status === 'ok') {
            dirs.vendor = resp.data.data;
          }
        });
      else
        axios.get('/accounting/dir/vendor').then((resp) => {
          if (resp.data.status === 'ok') {
            let res = [];
            resp.data.data.forEach((d) => res.push({ id: d.id, value: d.id, text: d.name, document_types: d.document_types }));
            dirs.vendor = res;
          }
        });
    }
    if (data == 'service' || !data)
      axios.get('/accounting/dir/service/select').then((resp) => {
        if (resp.data.status === 'ok') {
          let res = resp.data.data;
          res.forEach((d) => (d.disabled = d.status !== null || d.status == 2));
          let rr = [];
          res.forEach((r) => {
            rr[r.value] = r;
          });
          dirs.service = rr;
        }
      });
    if (data == 'cashbox' || !data)
      await axios.get('/accounting/dir/cashbox/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.cashbox = resp.data.data;
        }
      });
    if (data == 'object' || !data)
      await axios.get('/mechti/objects/select', { params: { filters: { name: { condition: '<>', value: '' } } } }).then((resp) => {
        if (resp.data.status === 'ok') {
          const res = resp.data.data;
          res.forEach((o) => (o.isActive = o.status >= 0));
          dirs.object = res;
        }
      });
    if (data == 'user' || !data)
      await axios.get('/mechti/tasks/users/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.user = resp.data.data;
        }
      });
    if (data == 'objectProps' || !data)
      await axios.get('mechti/object_props/select').then((resp) => {
        if (resp.data.status === 'ok') {
          dirs.objectProps = resp.data.data;
        }
      });
    //accounting/dir/service cashbox
    commit('setDirs', dirs);
  },
  async gettDirs({ state, commit, dispatch }, data) {
    if (!state.dirs?.[data])
      dispatch('fitchDirs', data).then((res) => {
        return state.dirs[data];
      });
    else return state.dirs[data];
  },
  async checkVersion({ state, commit, dispatch }, data) {
    let ver = state.version;
    axios.get('/config/config.version').then((resp) => {
      if (resp.data.status === 'ok') {
        ver.actual = resp.data.data;
        commit('setVersion', ver);
      }
    });
  },
};

const mutations = {
  set: (state, resp) => {
    state.data = resp;
  },

  setDirs: (state, resp) => {
    state.dirs = resp;
  },
  setVersion: (state, resp) => {
    state.version = resp;
  },
  setPhoneBar: (state, r) => {
    state.phoneBar = !state.phoneBar;
  },
  setLocalParams: (state, { name, value }) => {
    let params = state.params;
    if (!value && value !== 0) {
      delete params[name];
    } else {
      params[name] = value;
    }
    localStorage.setItem('user-params', JSON.stringify(params));
    state.params = params;
  },
  setSessionFilter: (state, { name, value }) => {
    let filters = state.filters;
    if (!value && value !== 0) {
      delete filters[name];
    } else {
      filters[name] = value;
    }
    state.filters = filters;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
