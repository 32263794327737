/** @format */
const readonly = true;
const hidden = true;
const dirs = {
  list: [
    { value: 1, text: 'Поставщики', api: 'accounting/dir/vendor', name: 'vendor', role: [0], view: true },
    { value: 2, text: 'Кассы', api: 'accounting/dir/cashbox', name: 'cash', role: [0] },
    { value: 3, text: 'Офисы', api: 'accounting/dir/office', name: 'office', role: [0] },
    { value: 12, text: 'Склады', api: 'accounting/dir/storage', name: 'storage', role: [0] },
    { value: 14, text: 'Кредитные организации', api: 'accounting/dir/credit_org', name: 'credit_org', role: [0] },
    { value: 15, text: 'Отделы', api: 'accounting/dir/department', name: 'department', role: [0] },
    { value: 9, text: 'Сотрудники', api: 'accounting/dir/employee', name: 'employee', role: [0], view: true },
    { value: 16, text: 'Типы товаров', api: 'mechti/goods_type', name: 'goods_type', role: [0] },
    { value: 21, text: 'Оборудование', api: 'accounting/dir/equipment', name: 'equipment', role: [0] },
    { value: 4, text: 'Категории', api: 'accounting/dir/service_category', name: 'category', role: [0], filters: { status: 1 } },
    { value: 5, text: 'Услуги', api: 'accounting/dir/service', name: 'service', role: [0] },
    { value: 20, text: 'Станции метро', api: 'mechti/underground_stations', name: 'metro', role: [0] },
    { value: 22, text: 'Жилые комплексы', api: 'mechti/residential_complex', name: 'residentia_complex', role: [0] },
    { value: 6, text: 'Счета', api: 'config', path: 'accounting.accountingTable', name: 'accountingTable', type: 'data', readonly, role: [0] },
    { value: 7, text: 'Операции по счетам', dir: 'operationTable', name: 'operationTable', type: 'data', readonly, role: [0] },
    { value: 8, text: 'Субконто', api: 'config', path: 'accounting.subcontoTable', name: 'subcontoTable', type: 'data', readonly, role: [0] },
    { value: 10, text: 'Настройки документов', api: 'config', path: 'accounting.docTable', name: 'docTable', type: 'data', readonly, role: [1000] },
    { value: 17, text: 'Шаблоны документов', api: 'mechti/doc_template', name: 'docTepmplate', role: [1000] },
    { value: 11, text: 'Роли', api: 'config', dir: 'roles', name: 'roles', type: 'data', readonly, role: [1000] },
    { value: 13, text: 'Свойства объекта', api: 'mechti/object_props', name: 'objectProps', role: [0] },
    { value: 18, text: 'Графики выплат', api: 'accounting/dir/loan_payment_schedule', name: 'loanSchedule', role: [1000] },
    { value: 19, text: 'Ставки размещения рекламы', api: 'mechti/cian_bet', name: 'adsBet', role: [0] },
  ],
  roles: {
    list: [
      { name: 'id', title: 'id', type: 'string', sortable: true, width: 300 },
      { name: 'value', title: 'value', type: 'string', sortable: true, width: 300 },
      { name: 'text', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'origin', title: 'Системное', type: 'string', sortable: true, width: 300 },
    ],
  },
  equipment: {
    accessName: 'dirs.equipment',
    title: 'Оборудование',
    list: [
      { name: 'id', title: 'id', type: 'string', sortable: true, width: 50 },
      { name: 'code', title: 'Инв. номер', type: 'string', sortable: true, width: 120 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'city', title: 'Город', type: 'select', options: 'city', sortable: true, width: 130 },
      { name: 'employee_id', title: 'Сотрудник', type: 'select', dir: 'employee', sortable: true, width: 130 },
      { name: 'value', title: 'Стоимость', type: 'number', sortable: true, width: 80 },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 300 },
    ],
    form: [
      { name: 'city', title: 'Город', type: 'select', options: 'city', validator: ['req'] },
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'code', title: 'Инв. номер', type: 'string' },
      { name: 'employee_id', title: 'Сотрудник', type: 'select', dir: 'employee' },
      { name: 'value', title: 'Стоимость', type: 'number', validator: ['req'] },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 1, validator: ['req'] },
    ],
  },
  metro: {
    list: [
      { name: 'id', title: 'ID', type: 'string', sortable: true, width: 50 },
      { name: 'city', title: 'Город', type: 'select', options: 'city', sortable: true, width: 130 },
      { name: 'name', title: 'Название', type: 'string', sortable: true, width: 280 },
      { name: 'cian_id', title: 'Код Циан', type: 'string', sortable: true, width: 130 },
    ],
    form: [
      { name: 'cian_id', title: 'Код Циан', type: 'string', validator: ['req'] },
      { name: 'city', title: 'Город', type: 'select', options: 'city', validator: ['req'] },
      { name: 'name', title: 'Название', type: 'string', validator: ['req'] },
    ],
  },
  adsBet: {
    list: [
      { name: 'id', title: 'id', type: 'string', sortable: true, width: 50 },
      { name: 'type', title: 'Тип', type: 'select', options: 'objectTypeLocation', sortable: true, width: 130 },
      { name: 'city', title: 'Город', type: 'select', options: 'city', sortable: true, width: 130 },
      { name: 'value_standart', title: 'Стандарт', type: 'number', sortable: true, width: 80 },
      { name: 'value_premium', title: 'Премиум', type: 'number', sortable: true, width: 80 },
      { name: 'value_top', title: 'Топ', type: 'number', sortable: true, width: 80 },
    ],
    form: [
      { name: 'type', title: 'Тип', type: 'select', options: 'objectTypeLocation', validator: ['req'] },
      { name: 'city', title: 'Город', type: 'select', options: 'city', validator: ['req'] },
      { name: 'value_standart', title: 'Стандарт', type: 'number', validator: ['req'] },
      { name: 'value_premium', title: 'Премиум', type: 'number', validator: ['req'] },
      { name: 'value_top', title: 'Топ', type: 'number', validator: ['req'] },
    ],
  },
  docTepmplate: {
    afterSave: 'none',
    list: [
      { name: 'id', title: 'id', type: 'string', sortable: true, width: 50 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'type', title: 'Тип', type: 'select', options: 'template_type', sortable: true, width: 150 },
      { name: 'notes', title: 'Описание', type: 'string', sortable: true, width: 300 },
      { name: 'content', title: 'Шаблон', type: 'editor2', hidden },
    ],
    form: [
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'type', title: 'Тип', type: 'select', options: 'template_type' },
      { name: 'notes', title: 'Описание', type: 'text', rows: 2 },
      //{ name: 'content', title: 'Шаблон', type: 'ckeditor5' },
      //{ name: 'content', title: 'Шаблон', type: 'editor2' },
      {
        name: 'result',
        title: 'Результат',
        type: 'buttonview',
        formula: '(d)=>{return d.content ? true : false}',
        computed: true,
        model: [{ name: 'content', title: 'Результат', type: 'html' }],
      },
      { name: 'content', title: 'Шаблон', type: 'text', rows: 6 },
    ],
  },
  objectProps: {
    list: [
      { name: 'id', title: 'id', type: 'string', sortable: true, width: 300 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'type', title: 'Тип', type: 'select', options: 'propsType', sortable: true, width: 300 },
      { name: 'measure', title: 'Ед.изм.', type: 'select', options: 'measure', sortable: true, width: 300 },
      { name: 'options', title: 'options', type: 'variant', sortable: true, width: 300, hidden: true },
      { name: 'data', title: 'data', type: 'variant', sortable: true, width: 300, hidden: true },
    ],
    form: [
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'type', title: 'Тип', type: 'select', options: 'propsType', validator: ['req'] },
      { name: 'measure', title: 'Ед.изм.', type: 'select', options: 'measure' },
      { name: 'options', type: 'options', title: 'Варианты выбора' },
      { name: 'data', title: 'data', type: 'variant', hidden1: true },
      { name: 'data.value', title: 'Добавочное значение', type: 'string', json1: true },
    ],
  },
  docTable: {
    list: [
      { name: 'id', title: 'id', type: 'string', sortable: true, width: 300 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
    ],
  },
  accountingTable: {
    list: [
      { name: 'id', title: 'id', type: 'string', sortable: true, width: 30 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'subconto1', title: 'Субконто 1', type: 'string', sortable: true, width: 150 },
      { name: 'subconto2', title: 'Субконто 2', type: 'string', sortable: true, width: 150 },
      { name: 'subconto3', title: 'Субконто 3', type: 'string', sortable: true, width: 150 },
    ],
  },
  operationTable: {
    list: [
      { name: 'id', title: 'id', type: 'string', sortable: true, width: 30 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'text', title: 'Текст', type: 'string', sortable: true, width: 300 },
      { name: 'debit', title: 'debit', type: 'string', sortable: true, width: 150 },
      { name: 'credit', title: 'credit', type: 'string', sortable: true, width: 150 },
    ],
  },
  subcontoTable: {
    list: [
      { name: 'id', title: 'id', type: 'string', sortable: true, width: 200 },
      { name: 'api', title: 'api', type: 'string', sortable: true, width: 200 },
      { name: 'table', title: 'table', type: 'string', sortable: true, width: 150 },
      { name: 'type', title: 'type', type: 'string', sortable: true, width: 50 },
    ],
  },

  vendor: {
    accessName: 'dirs.vendor',
    title: 'Поставщики',
    viewForm1: {
      fields: 'name,type,inn,ogrn,contact,phone',
    },
    viewForm2: {
      fields: 'yur_address,fact_address,bank_name,bik,account,korr_chet',
    },
    tabList: 'files',
    vendorTypeList: [
      { value: 1, text: 'Юридическое лицо' },
      { value: 2, text: 'Физическое лицо' },
      { value: 3, text: 'Индивидуальный предприниматель' },
    ],
    vendorDocList: [
      { value: 'AccountingDirContract_side1', text: 'Договоры (юр. лица компании)' },
      { value: 'AccountingDirContract', text: 'Договоры (поставщики)' },
    ],

    config: {
      configField: 'type',
      default_: {
        fields: 'createdby_id,name,type,phone,user_id,document_types.list,docs,filesRemove,filesAdd',
        fieldsRO: '',
        fieldsForm: 'createdby_id,name,type,phone#6,user_id,document_types.list,docs',
      },
      default: {
        fields: 'createdby_id,name,type',
        fieldsRO: '',
        fieldsForm: 'createdby_id,name,type',
      },
      config: [
        {
          value: 1,
          fields: 'createdby_id,name,type,inn,kpp,ogrn,yur_address,fact_address,account,bik,contact,phone,user_id,document_types.list,docs,filesRemove,filesAdd',
          fieldsForm:
            'createdby_id,name,type,provides_goods#6,provides_service#6,inn#6,kpp#6,ogrn,yur_address,fact_address,account#6,bank_name#6,korr_chet#6,bik#6,contact#6,phone#6,user_id,document_types.list,docs',
        },
        {
          value: 2,
          fields: 'createdby_id,name,type,inn,passport,fact_address,account,bik,contact,phone,user_id,document_types.list,docs,filesRemove,filesAdd',
          fieldsForm:
            'createdby_id,name,type,inn,provides_goods#6,provides_service#6,passport,fact_address,account#6,bank_name#6,korr_chet#6,bik#6,contact#6,phone#6,user_id,document_types.list,docs',
        },
        {
          value: 3,
          fields:
            'createdby_id,name,type,passport,inn,ogrn,yur_address,fact_address,account,bik,bank_name,contact,phone,user_id,document_types.list,docs,filesRemove,filesAdd',
          fieldsForm:
            'createdby_id,name,type,provides_goods#6,provides_service#6,passport,fact_address,yur_address,inn#6,ogrn#6,account#6,korr_chet#6,bik#6,bank_name#6,contact#6,phone#6,user_id,document_types.list,docs',
        },
      ],
    },
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'inn', title: 'ИНН', type: 'string', sortable: true, width: 200 },
      { name: 'type', title: 'Тип', type: 'select', sourceList: 'vendorTypeList', sortable: true, width: 200, default: 1 },
      { name: 'provides_service', title: 'Услуги', type: 'switcher', sortable: true, width: 100 },
      { name: 'provides_goods', title: 'Товары', type: 'switcher', sortable: true, width: 100 },
      { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', sortable: true, width: 200 },
    ],
    form: [
      { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', readonly: true, hiddenCreate: true },
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'type', title: 'Тип', type: 'select', sourceList: 'vendorTypeList', validator: ['req'] },
      { name: 'inn', title: 'ИНН', type: 'string', validator: ['req', 'unique'], table: 'accounting_dir_vendors' },
      { name: 'kpp', title: 'КПП', type: 'string', validator: ['req1'] },
      { name: 'ogrn', title: 'ОГРН', type: 'string', validator: ['req1'] },
      { name: 'yur_address', title: 'Юридический адрес', type: 'string', validator: ['req1'] },
      { name: 'fact_address', title: 'Фактический адрес', type: 'string', validator: ['req1'] },
      { name: 'account', title: 'Расчетный счет', type: 'string', validator: ['req1'] },
      { name: 'bik', title: 'БИК', type: 'string', validator: ['req1'] },
      { name: 'bank_name', title: 'Наименование банка', type: 'string', validator: ['req1'] },
      { name: 'korr_chet', title: 'Корреспондентский счёт', type: 'string', validator: ['req1'] },
      { name: 'contact', title: 'Контактное лицо', type: 'string', validator: ['req1'] },
      { name: 'phone', title: 'Телефон', type: 'string', validator: ['req1'] },
      { name: 'passport', title: 'Паспорт', type: 'string', validator: ['req1'] },
      { name: 'provides_service', title: 'Поставщик услуг', type: 'switcher', validator: ['req1'] },
      { name: 'provides_goods', title: 'Поставщик товаров', type: 'switcher', validator: ['req1'] },
      { name: 'user_id', title: 'Пользователь', type: 'selectapi', api: 'manager/users/select', filters: { status: 1 } },
      { name: 'document_types.list', title: 'Применять в документах', type: 'select', sourceList: 'vendorDocList', options1: 'config.accounting.docTable', chips: true },
      { name: 'docs', title: 'Документы', type: 'filesdata', hiddenCreate: true },
      { name: 'filesRemove', default: [], hidden: true },
      { name: 'filesAdd', default: [], hidden: true },
    ],
  },
  office: {
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
    ],
    form: [{ name: 'name', title: 'Наименование', type: 'string', validator: ['req'] }],
  },
  credit_org: {
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 400 },
    ],
    form: [{ name: 'name', title: 'Наименование', type: 'string', validator: ['req'] }],
  },
  loanSchedule: {
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'parent_id', title: 'Объект', type: 'select', dir: 'object', sortable: true, width: 300 },
    ],
    form: [
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'object_id', title: 'Объект', type: 'select', dir: 'object' },
      { name: 'loan_schedule', title: 'график', type: 'loanselect' },
    ],
  },
  storage: {
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', sortable: true, width: 300 },
    ],
    form: [
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'object_id', title: 'Объект', type: 'select', dir: 'object' },
    ],
  },

  cash: {
    cashTypeList: [
      { value: 1, text: 'Наличные средства' },
      { value: 2, text: 'Расчетный счет' },
      { value: 3, text: 'Пластиковая карта' },
      { value: 4, text: 'Ячейка в банке' },
    ],
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'type', title: 'Тип', type: 'select', sourceList: 'cashTypeList', sortable: true, width: 300 },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active' },
    ],
    form: [
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'type', title: 'Тип', type: 'select', sourceList: 'cashTypeList', default: 1, validator: ['req'] },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 1, validator: ['req'] },
    ],
  },
  category: {
    categoryTypeList: [
      { value: 1, text: 'Услуги' },
      { value: 2, text: 'Товары' },
    ],
    config: {
      configField: 'type',
      config: [
        {
          value: 1,
          fields: 'name,type,status',
          fieldsForm: 'status,name,type',
        },
        {
          value: 2,
          fields: 'name,type,manager_id,status,data.buying_rules,data.price_min_percent,data.price_max_percent',
          fieldsForm: 'status,name,type,manager_id,data.price_min_percent#6,data.price_max_percent#6,data.buying_rules',
        },
      ],
    },
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'type', title: 'Тип', type: 'select', sourceList: 'categoryTypeList', sortable: true, width: 300 },
      { name: 'manager_id', title: 'Сотрудник', type: 'select', dir: 'user', sortable: true, width: 300 },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 300 },
    ],
    form: [
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'type', title: 'Тип', type: 'select', sourceList: 'categoryTypeList', default: 1, validator: ['req'] },
      { name: 'manager_id', title: 'Сотрудник', type: 'selectapi', api: '/manager/users/select', filters: { role: [40, 45], status: 1 } },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 1, validator: ['req'] },
      { name: 'data.buying_rules', title: 'Планирование закупа', type: 'buyingrules' },
      { name: 'data.price_max_percent', title: 'Верхний предел цены (%)', type: 'number' },
      { name: 'data.price_min_percent', title: 'Нижний предел цены (%)', type: 'number' },
    ],
  },
  service: {
    config: {
      configField: 'category_id',
      default: {
        fields: 'name,category_id,status,data.description',
        fieldsForm: 'status,category_id,name,data.description',
      },
      config: [
        {
          value: [27, 28, 29, 100],
          fields:
            'name,category_id,data.work_days,data.work_value,status,data.description,price_comfort,price_business,price_premium,data.measure_type,data.comment_price_comfort,data.comment_price_business,data.comment_price_premium',
          fieldsForm:
            'status,category_id,name,price_comfort#3,data.comment_price_comfort#9,price_business#3,data.comment_price_business#9,price_premium#3,data.comment_price_premium#9,data.work_days#4,data.work_value#4,data.measure_type#4,data.description',
        },
      ],
    },
    measure_type: [
      { value: 1, text: '"S" помещения' },
      { value: 2, text: '"S" стен' },
    ],
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
      { name: 'category_id', title: 'Категория', type: 'select', dir: 'category', dirFilter: { type: 1 }, width: 300 },
      { name: 'price_comfort', title: 'Цена(комфорт)', type: 'number', sortable: true, width: 80 },
      { name: 'price_business', title: 'Цена(бизнес)', type: 'number', sortable: true, width: 80 },
      { name: 'price_premium', title: 'Цена(премиум)', type: 'number', sortable: true, width: 80 },
      { name: 'data.measure_type', title: 'Ед. расчета', type: 'select', sourceList: 'measure_type', width: 80 },
      { name: 'data.work_days', title: 'Норма дней', type: 'number', sortable: !true, width: 80 },
      { name: 'description', title: 'Описание', type: 'text', width: 300, formula: `((d) => {return d?.data?.description ? d.data.description : "";})` },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 300 },
    ],
    form: [
      { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'category_id', title: 'Категория', type: 'select', dir: 'category', dirFilter: { type: 1 }, validator: ['req'] },
      { name: 'price_comfort', title: 'Цена (комфорт)', type: 'number', validator: ['req1'] },
      { name: 'price_business', title: 'Цена (бизнес)', type: 'number', validator: ['req1'] },
      { name: 'price_premium', title: 'Цена (премиум)', type: 'number', validator: ['req1'] },
      { name: 'data.work_days', title: 'Норма дней', type: 'number', validator: ['req'] },
      { name: 'data.work_value', title: '"Вес" работы ', type: 'number', validator: ['req'] },
      { name: 'data.measure_type', title: 'Единица расчета', type: 'select', sourceList: 'measure_type' },
      { name: 'data.description', title: 'Описание', type: 'text' },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 1, validator: ['req'] },
      { name: 'data.comment_price_comfort', title: 'Коментарий к цене (комфорт)', type: 'text', rows: 1, opts: 'dense,auto-grow' },
      { name: 'data.comment_price_business', title: 'Коментарий к цене (бизнес)', type: 'text', rows: 1, opts: 'dense,auto-grow' },
      { name: 'data.comment_price_premium', title: 'Коментарий к цене (премиум)', type: 'text', rows: 1, opts: 'dense,auto-grow' },
    ],
  },

  employee: {
    accessName: 'dirs.employee',
    title: 'Сотрудник',
    viewForm1: {
      fields: 'department_id,date_hired,date_fired,date_birth,phone,address',
    },
    viewForm2: {
      fields: 'inn,passport_number,passport_info',
    },
    tabList: 'files,equipment',
    config: {
      default: {
        fields: 'name,department_id,user_id,inn,address,passport_number,passport_info,phone,date_birth,date_hired,date_fired,docs,filesRemove,filesAdd',
        fieldsRO: '',
        fieldsForm: 'name,date_birth#6,phone#6,address,department_id#6,date_hired#3,date_fired#3,inn#6,passport_number#6,passport_info,docs,filesRemove,filesAdd,user_id',
      },
    },
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Имя', type: 'string', sortable: true, width: 300 },
      { name: 'inn', title: 'ИНН', type: 'string', sortable: true, width: 200 },
      { name: 'department_id', title: 'Отдел', type: 'select', dir: 'department', sortable: true, width: 300 },
      { name: 'user_id', title: 'Пользователь', type: 'select', dir: 'user', sortable: true, width: 300 },
    ],
    form: [
      { name: 'name', title: 'Имя', type: 'string', validator: ['req'] },
      { name: 'department_id', title: 'Отдел', type: 'select', dir: 'department' },
      { name: 'inn', title: 'ИНН', type: 'string', validator: ['req1', 'unique'], table: 'accounting_dir_vendors' },
      { name: 'address', title: 'Адрес', type: 'string', validator: ['req1'] },
      { name: 'passport_number', title: 'Номер паспорта', type: 'string', validator: ['req1'] },
      { name: 'passport_info', title: 'Когда и кем выдан паспорт', type: 'string', validator: ['req1'] },
      { name: 'phone', title: 'Телефон', type: 'string', validator: ['req1'] },
      { name: 'date_birth', title: 'Дата рождения', type: 'datestring' },
      { name: 'date_hired', title: 'Дата приема', type: 'datestring' },
      { name: 'date_fired', title: 'Дата увольнения', type: 'datestring' },
      { name: 'user_id', title: 'Пользователь', type: 'select', dir: 'user', autocomplete: true },
      { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', hidden },
      { name: 'createdon', title: 'Дата создания', type: 'datetime', hidden },
      { name: 'docs', title: 'Документы', type: 'filesdata', hiddenCreate: true },
      { name: 'filesRemove', default: [], hidden: true },
      { name: 'filesAdd', default: [], hidden: true },
    ],
  },
  department: {
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Название отдела', type: 'string', sortable: true, width: 300 },
    ],
    form: [{ name: 'name', title: 'Название отдела', type: 'string', validator: ['req'] }],
  },
  goods_type: {
    config: {
      default: {
        fields:
          'name,category_id,rooms_types,measure,price_comfort,price_business,price_premium,status,data.comment_price_comfort,data.comment_price_business,data.comment_price_premium',
        fieldsRO: '',
        fieldsForm:
          'name,category_id,measure#3,rooms_types#9,price_comfort#3,data.comment_price_comfort#9,price_business#3,data.comment_price_business#9,price_premium#3,data.comment_price_premium#9,status',
      },
    },
    form: [
      { name: 'name', title: 'Название', type: 'string', validator: ['req'] },
      { name: 'category_id', title: 'Категория', type: 'select', dir: 'category', dirFilter: { type: 2, status: 1 }, validator: ['req'] },
      { name: 'rooms_types', title: 'Тип комнаты', type: 'select', options: 'room_type', chips: true },
      { name: 'measure', title: 'Ед.изм.', type: 'select', options: 'measure', validator: ['req'] },
      { name: 'price_comfort', title: 'Цена (комфорт)', type: 'number', validator: ['req'], size: 4 },
      { name: 'price_business', title: 'Цена (бизнес)', type: 'number', validator: ['req'] },
      { name: 'price_premium', title: 'Цена (премиум)', type: 'number', validator: ['req'] },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 1, validator: ['req'] },
      { name: 'data.comment_price_comfort', title: 'Коментарий к цене (комфорт)', type: 'text', rows: 1, opts: 'dense,auto-grow' },
      { name: 'data.comment_price_business', title: 'Коментарий к цене (бизнес)', type: 'text', rows: 1, opts: 'dense,auto-grow' },
      { name: 'data.comment_price_premium', title: 'Коментарий к цене (премиум)', type: 'text', rows: 1, opts: 'dense,auto-grow' },
      { name: 'data', return: 'object' },
    ],
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Название', type: 'string', sortable: true, width: 300 },
      { name: 'category_id', title: 'Категория', type: 'select', dir: 'category', dirFilter: { type: 2 }, sortable: true, width: 200 },
      { name: 'rooms_types', title: 'Тип комнаты', type: 'select', options: 'room_type', chips: true, sortable: true, width: 200 },
      { name: 'measure', title: 'Ед.изм.', type: 'select', options: 'measure', sortable: true, width: 80 },
      { name: 'price_comfort', title: 'Цена (комфорт)', type: 'number', sortable: true, width: 80 },
      { name: 'price_business', title: 'Цена (бизнес)', type: 'number', sortable: true, width: 80 },
      { name: 'price_premium', title: 'Цена (премиум)', type: 'number', sortable: true, width: 80 },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 1, validator: ['req'] },
    ],
  },
  residentia_complex: {
    config: {
      default: {
        fields: 'name,data.title,data.title_list,data.options_list,about,logo,images,identity',
        fieldsRO: '',
        fieldsForm: 'name,data.title,data.title_list,data.options_list,about,logo,images,identity',
      },
    },
    form: [
      { name: 'name', title: 'Название', type: 'string', validator: ['req'] },
      { name: 'data.title', title: 'Заголовок описания', type: 'string' },
      { name: 'data.title_list', title: 'Заголовок списка особенностей', type: 'text', rows: 1, opts: 'dense,auto-grow' },
      { name: 'data.options_list', title: 'Список особенностей', type: 'options' },
      { name: 'about', title: 'Описание', type: 'text', rows: 1, opts: 'dense,auto-grow' },
      { name: 'logo', title: 'Логотип', type: 'photo' },
      { name: 'images', title: 'Изображения', type: 'images' },
      { name: 'identity', title: 'Идентификатор', type: 'string' },
    ],
    list: [
      { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
      { name: 'name', title: 'Название', type: 'string', sortable: true, width: 300 },
      { name: 'identity', title: 'Идентификатор', type: 'images', width: 80 },
      { name: 'logo', title: 'Логотип', type: 'image', width: 80 },
    ],
  },
};
export default dirs;
