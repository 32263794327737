/** @format */

const cashOut = {
  title: 'Расходный кассовый ордер',
  accessName: 'cashOut',
  api: '/accounting/doc/order_withdrawal',
  apiStatus: '/accounting/doc/order_withdrawal',
  accDocName: 'AccountingDocOrderWithdrawalModel',
  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: !true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', width: 70, sortable: true },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', style: 'white-space: normal;', sortable: true },
    { name: 'value', title: 'Сумма', type: 'number', sortable: true },
    { name: 'vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor', sortable: true },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object', sortable: true },
    { name: 'cashbox_id', title: 'Касса', type: 'select', dir: 'cashbox' },
    { name: 'employee_id', title: 'Подотчет', type: 'select', dir: 'employee' },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { name: 'createdby_id', type: 'selectapi', api: '/manager/users/select' },
      sortable: true,
      width: 150,
    },
    {
      name: 'parent_id',
      title: 'Документ для оплаты',
      type: 'namedoc',
      doc_name: { key: 'parent_name' },
      doc_id: { key: 'parent_id' },
      style: 'white-space: normal;',
      hidden: true,
    },
    { name: 'parent_doc_name', title: 'Документ для оплаты', type: 'string', sortable: true, width: 120 },
    { name: 'count_id', title: 'Показано', hidden: true },
    { name: 'sum_value', title: 'Сумма', hidden: true },
  ],

  form: [
    { name: 'code_doc', title: 'Номер', type: 'number' },
    { name: 'date_doc', title: 'Дата документа', type: 'datetimestring' },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', validator: ['req'], autocomplete: true },
    { name: 'operation_name', title: 'Основание', type: 'string' },
    { name: 'object_id', title: 'Объект', type: 'select', dir: 'object' },
    { name: 'office_id', title: 'Офис', type: 'select', dir: 'office' },
    { name: 'cashbox_id1', title: 'Касса', type: 'select', dir: 'cashbox', validator: ['req'] },
    { name: 'cashbox_id', title: 'Касса', type: 'selectCashBox', dir: 'cashbox', validator: ['req'] },
    { name: 'second_cashbox_id', title: 'Касса получатель', type: 'select', dir: 'cashbox', validator: ['req'] },
    { name: 'client_id', title: 'Клиент', type: 'select', dir: 'client', validator: ['req'] },
    { name: 'vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor', validator: ['req'] },
    { name: 'investor_id', title: 'Инвестор', type: 'select', dir: 'investor', validator: ['req'] },
    { name: 'employee_id', title: 'Сотрудник', type: 'select', dir: 'employee', validator: ['req'] },
    { name: 'value', title: 'Сумма', type: 'number', validator: ['req'] },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1 },
    { name: 'files', title: 'Файлы', type: 'files' },
    { name: 'comments', title: 'Заметки', type: 'text', rows: 2 },
    { name: 'type', title: 'Тип', type: 'number', json: true },
    { name: 'parent_title', title: 'Документ для оплаты', type: 'string' },
    { name: 'parent_name', title: 'parent_name', type: 'string' },
    {
      name: 'parent_id',
      title: 'Документ для оплаты',
      type: 'selectapi',
      itemField: { value: 'id', text: 'code_doc' },
      apiVar: {
        key: 'parent_name',
        idField: 'parent_id',
        apiName: [
          { value: 'AccountingDocOrderGoodModel', name: 'accounting/doc/order_good', preText: 'Заказ товаров №' },
          { value: 'AccountingDocOrderServiceModel', name: 'accounting/doc/order_service', preText: 'Заказ услуг №' },
          { value: 'AccountingDocBuildingBuyModel', name: 'accounting/doc/building_buy', preText: 'Договор покупки №' },
          { value: 'RentModel', name: 'mechti/rent', preText: 'Договор покупки №' },
        ],
      },
    },

    {
      name: 'object_code',
      title: 'Назначение',
      type: 'select',
      dirVar: {
        key: 'type',
        dirName: [
          { value: 1, name: 'object' },
          { value: 2, name: 'office' },
        ],
      },
      json: true,
    },
    { name: 'credit_org_id', title: 'Кредитная организация', type: 'selectapi', api: 'accounting/dir/credit_org/select', validator: ['req'], card: 1 },
    {
      name: 'import_data',
      title: 'Данные импорта',
      type: 'buttonview',
      model: [{ name: 'import_data', title: 'Данные импорта', type: 'object' }],
      class: ' align-self-center',
    },
  ],
  config: [
    {
      id: -1,
      template: 'cashRaw',
      fields: 'operation_type,parent_id,parent_name,cashbox_id,employee_id,object_id,investor_id,value,status,files',
    },
    {
      id: 3,
      note: 'Оплата заказа товаров из кассы',
      balanceCheck: !true,
      template: 'cashOrder',
      template2: 'cashParent',
      fieldsReplace: {
        balance: {
          name: 'balance',
          overpay: 'На баланс поставщика',
          title: 'Баланс',
          type: 'balanceView',
          paramSQL: { account: '60.1', doc_name: '(d)=>{return d.parent_name}', doc_id: '(d)=>{return d.parent_id}' },
        },
        parent_title: { name: 'parent_title', title: 'Заказ товаров для оплаты', type: 'string' },
        parent_id: {
          name: 'parent_id',
          title: 'Заказ товаров для оплаты',
          type: 'selectapi',
          validator: ['req'],
          api: {
            type: 'post',
            api: '/report_sql',
            data: { name: 'getDocName', params: { name: 'AccountingDocOrderGoodModel', id: '(function(d) {return d.parent_id;})' } },
          },
        },
      },
      fields: 'date_doc,operation_type,parent_id,parent_title,object_id,object_code,type,balance,cashbox_id,vendor_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type,parent_id1,parent_title,object_id,vendor_id,object_code',
      fieldsForm: 'date_doc#4,parent_id,vendor_id#6,object_code#6,cashbox_id+value#6,balance#6,operation_name,note,files',
      document: {
        field: 'order_good_id',
        model: 'documentOrderGoods',
        api: '/accounting/doc/order_good',
        accDocName: 'AccountingDocOrderGoodModel',
        docFields: 'code_doc,createdon,type,object_code,vendor_id,total_price,createdby_id',
      },
    },
    {
      id: 5,
      note: 'Оплата заказа товара из подотчета',
      template: 'cashOrder',
      balanceCheck: true,
      fieldsReplace: {
        balance: {
          name: 'balance',
          title: 'Баланс',
          type: 'balanceView',
          paramSQL: { account: '60.1', doc_name: '(d)=>{return d.parent_name}', doc_id: '(d)=>{return d.parent_id}' },
        },
        parent_title: { name: 'parent_title', title: 'Заказ товаров для оплаты', type: 'string' },
        parent_id: {
          name: 'parent_id',
          title: 'Заказ товаров для оплаты',
          type: 'selectapi',
          validator: ['req'],
          api: {
            type: 'post',
            api: '/report_sql',
            data: { name: 'getDocName', params: { name: 'AccountingDocOrderGoodModel', id: '(function(d) {return d.parent_id;})' } },
          },
        },
      },
      fields: 'date_doc,operation_type,parent_title,parent_id,object_id,object_code,balance,employee_id,vendor_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type,parent_title,object_id,vendor_id,object_code',
      fieldsForm: 'date_doc#4,parent_id,vendor_id#6,object_code#6,employee_id+value#6,balance#6,operation_name,note,files',
      document: {
        field: 'order_good_id',
        model: 'documentOrderGoods',
        api: '/accounting/doc/order_good',
        accDocName: 'AccountingDocOrderGoodModel',
        docFields: 'code_doc,createdon,type,object_code,vendor_id,total_price,createdby_id',
      },
    },
    {
      id: 69,
      note: 'Оплата заказа товара с баланса поставщика',
      template: 'cashOrder',
      balanceCheck: true,
      fieldsReplace: {
        balance: {
          name: 'balance',
          title: 'Баланс',
          type: 'balanceView',
          paramSQL: { account: '60.1', doc_name: '(d)=>{return d.parent_name}', doc_id: '(d)=>{return d.parent_id}' },
        },
        balanceVendor: {
          name: 'balanceVendor',
          title: 'Баланс поставщика',
          type: 'balanceApi',
          account: '60.5',
          validator: ['min#0', 'ignoreChange'],
          subconto1: '((d) => {return d.vendor_id;})',
        },
        parent_title: { name: 'parent_title', title: 'Заказ товаров для оплаты', type: 'string' },
        parent_id: {
          name: 'parent_id',
          title: 'Заказ товаров для оплаты',
          type: 'selectapi',
          validator: ['req'],
          api: {
            type: 'post',
            api: '/report_sql',
            data: { name: 'getDocName', params: { name: 'AccountingDocOrderGoodModel', id: '(function(d) {return d.parent_id;})' } },
          },
        },
      },
      fields: 'date_doc,operation_type,parent_title,parent_id,object_id,object_code,balanceVendor,balance,vendor_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type,parent_title,object_id,vendor_id,object_code',
      fieldsForm: 'date_doc,parent_id,vendor_id#6,balanceVendor#6,value#6,balance#6,operation_name,note,files',
      document: {
        field: 'order_good_id',
        model: 'documentOrderGoods',
        api: '/accounting/doc/order_good',
        accDocName: 'AccountingDocOrderGoodModel',
        docFields: 'code_doc,createdon,type,object_code,vendor_id,total_price,createdby_id',
      },
    },
    {
      id: 68,
      note: 'Переоплата заказа товара в счет баланса поставщика',
      template: 'cashOrder',
      fieldsReplace: {
        balance_vendor: {
          name: 'balanceVendor',
          title: 'Баланс поставщика',
          type: 'balanceApi',
          account: '60.5',
          value: 1,
          subconto1: '((d) => {return d.vendor_id;})',
        },
        parent_title: { name: 'parent_title', title: 'Заказ товаров для оплаты', type: 'string' },
        parent_id: {
          name: 'parent_id',
          title: 'Заказ товаров для оплаты',
          type: 'selectapi',
          validator: ['req'],
          api: {
            type: 'post',
            api: '/report_sql',
            data: { name: 'getDocName', params: { name: 'AccountingDocOrderGoodModel', id: '(function(d) {return d.parent_id;})' } },
          },
        },
      },
      fields: 'date_doc,operation_type,parent_title,parent_id,object_id,object_code,vendor_id,value,status,note,files,cashbox_id,operation_name',
      fieldsRO: 'operation_type,parent_title,object_id,vendor_id,object_code',
      fieldsForm: 'date_doc,parent_id,vendor_id#6,object_code#6,balanceVendor,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'order_good_id',
        model: 'documentOrderGoods',
        api: '/accounting/doc/order_good',
        accDocName: 'AccountingDocOrderGoodModel',
        docFields: 'code_doc,createdon,type,object_code,vendor_id,createdby_id',
      },
    },
    {
      id: 4,
      note: 'Оплата заказа услуг из кассы',
      template: 'cashOrder',
      fieldsReplace: {
        balance: {
          name: 'balance',
          title: 'Баланс',
          type: 'balanceView',
          paramSQL: { account: '60.2', doc_name: '(d)=>{return d.parent_name}', doc_id: '(d)=>{return d.parent_id}' },
        },
        parent_title: { name: 'parent_title', title: 'Заказ услуг для оплаты', type: 'string' },
        parent_id: {
          name: 'parent_id',
          title: 'Заказ услуг для оплаты',
          type: 'selectapi',
          validator: ['req'],
          api: {
            type: 'post',
            api: '/report_sql',
            data: { name: 'getDocName', params: { name: 'AccountingDocOrderServiceModel', id: '(function(d) {return d.parent_id;})' } },
          },
        },
      },
      fields: 'date_doc,operation_type,parent_id,object_id,object_code,cashbox_id,balance,vendor_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type,parent_id,object_id,vendor_id,object_code',
      fieldsForm: 'date_doc,parent_id,vendor_id#6,object_code#6,cashbox_id+value#6,balance#6,operation_name,note,files',
      document: {
        field: 'order_service_id',
        model: 'documentOrderService',
        api: '/accounting/doc/order_service',
        accDocName: 'AccountingDocOrderServiceModel',
        docFields: 'code_doc,createdon,type,object_code,vendor_id,total_price,createdby_id',
      },
    },
    {
      id: 6,
      note: 'Оплата заказа услуг из подотчета',
      template: 'cashOrder',
      fieldsReplace: {
        balance: {
          name: 'balance',
          title: 'Баланс',
          type: 'balanceView',
          paramSQL: { account: '60.2', doc_name: '(d)=>{return d.parent_name}', doc_id: '(d)=>{return d.parent_id}' },
        },
        parent_title: { name: 'parent_title', title: 'Заказ услуг для оплаты', type: 'string' },
        parent_id: {
          name: 'parent_id',
          title: 'Заказ услуг для оплаты',
          type: 'selectapi',
          validator: ['req'],
          api: {
            type: 'post',
            api: '/report_sql',
            data: { name: 'getDocName', params: { name: 'AccountingDocOrderServiceModel', id: '(function(d) {return d.parent_id;})' } },
          },
        },
      },
      fields: 'date_doc,operation_type,parent_id,object_id,object_code,employee_id,vendor_id,balance,value,status,note,files,operation_name',
      fieldsRO: 'operation_type,parent_id,object_id,vendor_id,object_code',
      fieldsForm: 'date_doc,parent_id,vendor_id#6,object_code#6,employee_id+value#6,balance#6,operation_name,note,files',
      document: {
        field: 'order_service_id',
        model: 'documentOrderService',
        api: '/accounting/doc/order_service',
        accDocName: 'AccountingDocOrderServiceModel',
        docFields: 'code_doc,createdon,type,object_code,vendor_id,createdby_id',
      },
    },
    {
      id: 62,
      note: 'Оплата заказа услуг инвестором',
      template: 'cashOrder',
      fieldsReplace: {
        balance: {
          name: 'balance',
          title: 'Баланс',
          type: 'balanceView',
          paramSQL: { account: '60.2', doc_name: '(d)=>{return d.parent_name}', doc_id: '(d)=>{return d.parent_id}' },
        },
        parent_title: { name: 'parent_title', title: 'Заказ услуг для оплаты', type: 'string' },
        parent_id: {
          name: 'parent_id',
          title: 'Заказ услуг для оплаты',
          type: 'selectapi',
          validator: ['req'],
          api: {
            type: 'post',
            api: '/report_sql',
            data: {
              name: 'getDocName',
              params: {
                name: 'AccountingDocOrderServiceModel',
                id: '(function(d) {return d.parent_id;})',
              },
            },
          },
        },
      },
      fields: 'date_doc,operation_type,parent_id,object_id,object_code,investor_id,vendor_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type,parent_id,object_id,vendor_id,object_code',
      fieldsForm: 'date_doc,parent_id,vendor_id#6,object_id#6,investor_id+value#6,balance#6,operation_name,note,files',
      document: {
        field: 'order_service_id',
        model: 'documentOrderService',
        api: '/accounting/doc/order_service',
        accDocName: 'AccountingDocOrderServiceModel',
        docFields: 'code_doc,createdon,type,object_code,vendor_id,createdby_id',
        fieldsConfig: {
          'data.object_code': { type: 'field', value: 'object_code', dataType: 'number' },
          'data.type': { type: 'value', value: 1 },
          object_id: { type: 'field', value: 'object_code', dataType: 'number' },
          investor_id: { type: 'field', value: 'investor_id' },
          vendor_id: { type: 'field', value: 'vendor_id' },
          parent_id: { type: 'field', value: 'id' },
          value: { type: 'field', value: 'total_price' },
        },
      },
    },
    {
      id: 7,
      note: 'Выдача денег в подотчет',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,cashbox_id,employee_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,cashbox_id#6,employee_id#6,balance,value,operation_name,note,files',
    },
    {
      id: 13,
      note: 'Передача между кассами',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,cashbox_id,second_cashbox_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,cashbox_id#6,second_cashbox_id#6,value,operation_name,note,files',
    },
    {
      id: 17,
      note: 'Оплата документа покупки недвижимости',
      template: 'cashOrder',
      fields: 'date_doc,operation_type,parent_id,object_id,object_code,cashbox_id,vendor_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type,parent_id,object_id,vendor_id,object_code',
      fieldsForm: 'date_doc,parent_id,vendor_id#6,object_code#6,balance,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'documentObjectBuy',
        api: '/accounting/doc/building_buy',
        accDocName: 'AccountingDocBuildingBuyModel',
        docFields: 'id,createdon,object_id,seller_id,createdby_id',
        fieldsConfig: {
          vendor_id: { type: 'field', value: 'seller_id' },
          object_id: { type: 'field', value: 'object_id' },
          'data.object_code': { type: 'field', value: 'object_id' },
          'data.type': { type: 'value', value: 1 },
        },
      },
    },
    {
      id: 30,
      note: 'Выплата средств арендодателю',
      template: 'cashParent',
      fieldsReplace: {
        parent_id: {
          name: 'parent_id',
          title: 'Основание для оплаты',
          type: 'selectapi',
          itemField: { value: 'id', text: 'name' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'RentModel', name: 'mechti/rent', preText: 'Аренда:' }] },
        },
        client_id: { name: 'client_id', title: 'Клиент', type: 'selectapi', api: 'mechti/clients/select', optionsFilter: { client_type: 'bit#6' }, validator: ['req'] },
      },
      fields: 'date_doc,operation_type,parent_id,cashbox_id,client_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type,parent_id,client_id',
      fieldsForm: 'date_doc,parent_id#6,client_id#6,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'rent',
        api: 'mechti/rent',
        accDocName: 'RentModel',
        docFields: 'id,name,landlord_client_id',
        fieldsConfig: {
          object_rent_id: { type: 'field', value: 'id' },
          client_id: { type: 'field', value: 'landlord_client_id' },
          value: { type: 'field', value: 'amount_landlord' },
        },
      },
    },

    {
      id: 31,
      note: 'Расход по объекту аренды',
      template: 'cashParent',
      fieldsReplace: {
        parent_id: {
          name: 'parent_id',
          title: 'Основание для оплаты',
          type: 'selectapi',
          itemField: { value: 'id', text: 'name' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'RentModel', name: 'mechti/rent', preText: 'Аренда:' }] },
        },
      },
      fields: 'date_doc,operation_type,parent_id,cashbox_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type,parent_id',
      fieldsForm: 'date_doc,parent_id#12,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'rent',
        api: 'mechti/rent',
        accDocName: 'RentModel',
        docFields: 'id,name,address,landlord_client_id',
        fieldsConfig: {
          object_rent_id: { type: 'field', value: 'id' },
          value: { type: 'field', value: 'amount_landlord' },
        },
      },
    },
    {
      id: 33,
      note: 'Вывод средств учредителем',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,cashbox_id,employee_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,cashbox_id#6,value#6,employee_id,operation_name,note,files',
    },

    {
      id: 37,
      note: 'Выплаты по кредиту компанией',
      name: 'Выплаты по кредиту компанией',
      template: 'cashParent',
      fieldsReplace: {
        'data.loan_select': {
          name: 'data.loan_select',
          title: 'Выбор кредитного платежа',
          type: 'loanselect',
          onChange: {
            value: {
              validator:
                '(d) => {let val=parseFloat(d.data.loan_select?.value || 0) + parseFloat(d.data.loan_select?.value_percent || 0) + parseFloat(d.data.loan_select?.value_commission || 0); return ["req","min#"+val.toFixed(2)]}',
              formula:
                '(d) => {return (parseFloat(d.data.loan_select?.value || 0) + parseFloat(d.data.loan_select?.value_percent || 0) + parseFloat(d.data.loan_select?.value_commission || 0)).toFixed(2);}',
            },
          },
        },
        parent_id: {
          validator: ['req'],
          name: 'parent_id',
          title: 'Документ',
          type: 'selectapi',
          itemField: { value: 'id', text: 'code_doc' },
          apiVar: {
            key: 'parent_name',
            apiName: [
              { value: 'AccountingDocBuildingBuyModel', name: 'accounting/doc/building_buy', preText: 'Покупка в кредит №' },
              { value: 'AccountingDocGettingLoan', name: 'accounting/doc/getting_loan', preText: 'Взятие займа №' },
            ],
          },
          validator: ['req'],
        },
      },
      fields: 'date_doc,operation_type,parent_id,object_id,investor_id,cashbox_id,value,status,note,files,data.loan_select,credit_org_id,operation_name',
      fieldsRO: 'operation_type,parent_id,object_id,investor_id',
      fieldsForm: 'date_doc,parent_id#6,object_id#6,credit_org_id,investor_id,data.loan_select,cashbox_id#6,value#6,operation_name,note,files',
      document_: {
        field: 'parent_id',
        model: 'documentObjectBuy',
        api: 'accounting/doc/building_buy',
        accDocName: 'AccountingDocBuildingBuyModel',
        docFields: 'id,object_id,investor_id',
        filter: { status: 2 },
        fieldsConfig: {
          object_id: { type: 'field', value: 'object_id' },
          investor_id: { type: 'field', value: 'investor_id' },
          value: { type: 'value', value: null },
          credit_org_id: { type: 'field', value: 'credit_org_id' },
          'data.loan_select': { type: 'value', value: {} },
        },
      },
      document: {
        field: 'parent_id',
        model: 'documentLoanGet',
        api: 'accounting/doc/Getting_Loan',
        accDocName: 'AccountingDocGettingLoan',
        docFields: 'id,createdon,code_doc,object_id,investor_id,createdby_id',
        filter: { status: 2 },
        fieldsConfig: {
          object_id: { type: 'field', value: 'object_id' },
          investor_id: { type: 'field', value: 'investor_id' },
          value: { type: 'value', value: null },
          credit_org_id: { type: 'field', value: 'credit_org_id' },
          'data.loan_select': { type: 'value', value: {} },
        },
      },
    },
    {
      id: 42,
      note: 'Выплата кредита инвестором',
      name: 'Выплата кредита инвестором',
      template: 'cashParent',
      fieldsReplace: {
        'data.loan_select': {
          validator: ['req'],
          name: 'data.loan_select',
          title: 'Выбор кредитного платежа',
          type: 'loanselect',
          onChange: {
            value: {
              validator:
                '(d) => {let val=parseFloat(d.data.loan_select?.value || 0) + parseFloat(d.data.loan_select?.value_percent || 0) + parseFloat(d.data.loan_select?.value_commission || 0); return ["req","min#"+val]}',
              formula:
                '(d) => {return parseFloat(d.data.loan_select?.value || 0) + parseFloat(d.data.loan_select?.value_percent || 0) + parseFloat(d.data.loan_select?.value_commission || 0);}',
            },
          },
        },
        parent_id: {
          validator: ['req'],
          name: 'parent_id',
          title: 'Документ',
          type: 'selectapi',
          itemField: { value: 'id', text: 'code_doc' },
          apiVar: {
            key: 'parent_name',
            apiName: [
              { value: 'AccountingDocBuildingBuyModel', name: 'accounting/doc/building_buy', preText: 'Покупка в кредит №' },
              { value: 'AccountingDocGettingLoan', name: 'accounting/doc/getting_loan', preText: 'Взятие займа №' },
            ],
          },
          validator: ['req'],
        },
      },
      fields: 'date_doc,operation_type,parent_id,object_id,investor_id,value,status,note,files,data.loan_select,credit_org_id,operation_name',
      fieldsRO: 'operation_type,parent_id,object_id,investor_id',
      fieldsForm: 'date_doc,parent_id#6,object_id#6,credit_org_id,investor_id,data.loan_select,value#6,operation_name,note,files',
      document_: {
        field: 'parent_id',
        model: 'documentObjectBuy',
        api: 'accounting/doc/building_buy',
        accDocName: 'AccountingDocBuildingBuyModel',
        docFields: 'id,object_id,investor_id,seller_id,createdby_id',
        filter: { status: 2 },
        fieldsConfig: {
          object_id: { type: 'field', value: 'object_id' },
          investor_id: { type: 'field', value: 'investor_id' },
          value: { type: 'value', value: null },
          credit_org_id: { type: 'field', value: 'credit_org_id' },
          'data.loan_select': { type: 'value', value: {} },
        },
      },
      document: {
        field: 'parent_id',
        model: 'documentLoanGet',
        api: 'accounting/doc/Getting_Loan',
        accDocName: 'AccountingDocGettingLoan',
        docFields: 'id,createdon,code_doc,object_id,investor_id,createdby_id',
        filter: { status: 2 },
        fieldsConfig: {
          object_id: { type: 'field', value: 'object_id' },
          investor_id: { type: 'field', value: 'investor_id' },
          value: { type: 'value', value: null },
          credit_org_id: { type: 'field', value: 'credit_org_id' },
          'data.loan_select': { type: 'value', value: {} },
        },
      },
    },
    {
      id: 66,
      note: 'Закрытие кредита компанией',
      name: 'Закрытие кредита компанией',
      template: 'cashParent',
      fieldsReplace: {
        'data.loan_select': {
          name: 'data.loan_select',
          title: 'Выбор кредитного платежа',
          type: 'loanselect',
          typeAction: 'close',

          onChange: {
            value: {
              validator:
                '(d) => {let val=parseFloat(d.data.loan_select?.value || 0) + parseFloat(d.data.loan_select?.value_percent || 0) + parseFloat(d.data.loan_select?.value_commission || 0); return ["req","min#"+val]}',
              formula:
                '(d) => {return parseFloat(d.data.loan_select?.value || 0) + parseFloat(d.data.loan_select?.value_percent || 0) + parseFloat(d.data.loan_select?.value_commission || 0);}',
            },
          },
        },
        parent_id: {
          validator: ['req'],
          name: 'parent_id',
          title: 'Документ',
          type: 'selectapi',
          itemField: { value: 'id', text: 'code_doc' },
          apiVar: {
            key: 'parent_name',
            apiName: [{ value: 'AccountingDocGettingLoan', name: 'accounting/doc/getting_loan', preText: 'Взятие займа №' }],
          },
          validator: ['req'],
        },
      },
      fields: 'date_doc,operation_type,parent_id,object_id,investor_id,cashbox_id,value,status,note,files,data.loan_select,credit_org_id,operation_name',
      fieldsRO: 'operation_type,parent_id,object_id,investor_id',
      fieldsForm: 'date_doc,parent_id#6,object_id#6,credit_org_id,investor_id,data.loan_select,cashbox_id#6,value#6,operation_name,note,files',

      document: {
        field: 'parent_id',
        model: 'documentLoanGet',
        api: 'accounting/doc/Getting_Loan',
        accDocName: 'AccountingDocGettingLoan',
        docFields: 'id,createdon,code_doc,object_id,investor_id,createdby_id',
        filter: { status: 2 },
        fieldsConfig: {
          object_id: { type: 'field', value: 'object_id' },
          investor_id: { type: 'field', value: 'investor_id' },
          value: { type: 'value', value: null },
          credit_org_id: { type: 'field', value: 'credit_org_id' },
          'data.loan_select': { type: 'value', value: {} },
        },
      },
    },
    {
      id: 67,
      note: 'Закрытие кредита инвестом',
      name: 'Закрытие кредита инвестом',
      template: 'cashParent',
      fieldsReplace: {
        balance1: {
          name: 'balance',
          type: 'balance',
        },
        'data.loan_select': {
          validator: ['req'],
          name: 'data.loan_select',
          title: 'Выбор кредитного платежа',
          type: 'loanselect',
          typeAction: 'close',

          onChange: {
            value: {
              validator:
                '(d) => {let val=parseFloat(d.data.loan_select?.value || 0) + parseFloat(d.data.loan_select?.value_percent || 0) + parseFloat(d.data.loan_select?.value_commission || 0); return ["req","min#"+val]}',
              formula:
                '(d) => {return parseFloat(d.data.loan_select?.value || 0) + parseFloat(d.data.loan_select?.value_percent || 0) + parseFloat(d.data.loan_select?.value_commission || 0);}',
            },
          },
        },
        parent_id: {
          validator: ['req'],
          name: 'parent_id',
          title: 'Документ',
          type: 'selectapi',
          itemField: { value: 'id', text: 'code_doc' },
          apiVar: {
            key: 'parent_name',
            apiName: [{ value: 'AccountingDocGettingLoan', name: 'accounting/doc/getting_loan', preText: 'Взятие займа №' }],
          },
          validator: ['req'],
        },
      },
      fields: 'date_doc,operation_type,parent_id,object_id,investor_id,value,status,note,files,data.loan_select,credit_org_id,operation_name',
      fieldsRO: 'operation_type,parent_id,object_id,investor_id',
      fieldsForm: 'date_doc,parent_id#6,object_id#6,credit_org_id,investor_id,data.loan_select,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'documentLoanGet',
        title: 'Выбор основания для оплаты',
        api: 'accounting/doc/Getting_Loan',
        accDocName: 'AccountingDocGettingLoan',
        docFields: 'id,createdon,code_doc,object_id,investor_id,createdby_id',
        filter: { status: 2 },
        fieldsConfig: {
          object_id: { type: 'field', value: 'object_id' },
          investor_id: { type: 'field', value: 'investor_id' },
          value: { type: 'value', value: null },
          credit_org_id: { type: 'field', value: 'credit_org_id' },
          'data.loan_select': { type: 'value', value: {} },
        },
      },
    },
    {
      id: 51,
      note: 'Оплата первичного взноса',
      template: 'cashParent',
      fieldsReplace: {
        vendor_id: { name: 'vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor' },
        parent_id: {
          validator: ['req'],
          name: 'parent_id',
          title: 'Документ',
          type: 'selectapi',
          itemField: { value: 'id', text: 'code_doc' },
          apiVar: {
            key: 'parent_name',
            apiName: [
              { value: 'AccountingDocBuildingBuyModel', name: 'accounting/doc/building_buy', preText: 'Покупка в кредит №' },
              { value: 'AccountingDocGettingLoan', name: 'accounting/doc/getting_loan', preText: 'Взятие займа №' },
            ],
          },
          validator: ['req'],
        },
      },
      fields: 'date_doc,operation_type,parent_id,object_id,object_code,cashbox_id,vendor_id,value,status,note,files,operation_name',
      fieldsRO: 'operation_type,parent_id,object_id,vendor_id,object_code,value',
      fieldsForm: 'date_doc,parent_id,vendor_id#6,object_id#6,balance,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model_: 'documentObjectBuy',
        api_: '/accounting/doc/building_buy',
        model: 'documentLoanGet',
        api: '/accounting/doc/getting_loan',
        filter: { status: 2, first_payment_type: 1 },
        accDocName_: 'AccountingDocBuildingBuyModel',
        accDocName: 'AccountingDocGettingLoan',
        docFields: 'id,createdon,code_doc,object_id,borrower_id,investor_id,createdby_id',
        fieldsConfig: {
          vendor_id: { type: 'field', value: 'seller_id' },
          value: { type: 'field', value: 'value_first' },
          object_id: { type: 'field', value: 'object_id' },
          'data.object_code': { type: 'field', value: 'object_id' },
        },
      },
    },
    {
      id: 55,
      note: 'Расход прочее',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,cashbox_id,value,status,files,comments,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,cashbox_id#6,value#6,operation_name,comments,files',
    },
    {
      id: 58,
      note: 'Оказание брокерских услуг',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,cashbox_id,value,status,files,comments,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,cashbox_id#6,value#6,operation_name,comments,files',
    },
    {
      id: 47,
      note: 'Оплата по акту подрядчика',
      template: 'cashParent',
      balanceCheck: true,
      fieldsReplace: {
        balance: {
          name: 'balance',
          title: 'Баланс',
          type: 'balanceView',
          paramSQL: { account: '60.4', doc_name: '(d)=>{return d.parent_name}', doc_id: '(d)=>{return d.parent_id}' },
        },
        parent_id: {
          name: 'parent_id',
          title: 'Основание для оплаты',
          type: 'selectapi',
          itemField: { value: 'id', text: 'code_doc' },
          filters: { formula: '(d)=>{return {id: d.parent_id}}' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'AccountingDocActWork', name: '/accounting/doc/act_work', preText: 'Акт подрядчика:' }] },
        },
      },
      fields: 'date_doc,operation_type,parent_id,cashbox_id,value,status,note,files,vendor_id,balance,operation_name',
      fieldsRO: 'operation_type,parent_id,vendor_id,value_',
      fieldsForm: 'date_doc,parent_id#6,vendor_id#6,cashbox_id+value#6,balance#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'documentActWork',
        api: '/accounting/doc/act_work',
        accDocName: 'AccountingDocActWork',
        docFields: 'code_doc,object_id,work_name,vendor_id,balance',
        filter: { status: [2] },
        fieldsConfig: {
          vendor_id: { type: 'field', value: 'vendor_id' },
          object_id: { type: 'field', value: 'object_id' },
          value: { type: 'field', value: 'value' },
        },
      },
    },
    {
      id: 65,
      note: 'Оплата по акту подрядчика из подотчета',
      template: 'cashParent',
      balanceCheck: true,
      fieldsReplace: {
        balance: {
          name: 'balance',
          title: 'Баланс',
          type: 'balanceView',
          paramSQL: { account: '60.4', doc_name: '(d)=>{return d.parent_name}', doc_id: '(d)=>{return d.parent_id}' },
        },
        parent_id: {
          name: 'parent_id',
          title: 'Основание для оплаты',
          type: 'selectapi',
          filters: { formula: '(d)=>{return {id: d.parent_id}}' },
          itemField: { value: 'id', text: 'code_doc' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'AccountingDocActWork', name: '/accounting/doc/act_work', preText: 'Акт подрядчика:' }] },
        },
      },
      fields: 'date_doc,operation_type,parent_id,value,status,note,files,vendor_id,employee_id,balance,operation_name',
      fieldsRO: 'operation_type,parent_id,vendor_id,value_',
      fieldsForm: 'date_doc,parent_id#6,vendor_id#6,employee_id+value#6,balance#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'documentActWork',
        api: '/accounting/doc/act_work',
        accDocName: 'AccountingDocActWork',
        docFields: 'code_doc,object_id,work_name,vendor_id,balance',
        filter: { status: [2] },
        fieldsConfig: {
          vendor_id: { type: 'field', value: 'vendor_id' },
          object_id: { type: 'field', value: 'object_id' },
          value: { type: 'field', value: 'value' },
        },
      },
    },
    {
      id: 73,
      note: 'Возврат средств клиенту по аренде',
      template: 'cashParent',
      fieldsReplace: {
        parent_id: {
          name: 'parent_id',
          title: 'Основание для возврата',
          type: 'selectapi',
          itemField: { value: 'id', text: 'name' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'RentModel', name: 'mechti/rent', preText: 'Аренда:' }] },
        },
        client_id: { name: 'client_id', title: 'Клиент', type: 'selectapi', api: 'mechti/clients/select', optionsFilter: { client_type: 'bit#5' }, validator: ['req'] },
      },
      fields: 'date_doc,operation_type,parent_id,cashbox_id,client_id,value,status,note,files,filesAdd,filesRemove,operation_name',
      fieldsRO: 'operation_type,parent_id,client_id',
      fieldsForm: 'date_doc,parent_id#6,client_id#6,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'rent',
        api: 'mechti/rent',
        accDocName: 'RentModel',
        docFields: 'id,name,renter_client_id',
        fieldsConfig: {
          object_rent_id: { type: 'field', value: 'id' },
          client_id: { type: 'field', value: 'renter_client_id' },
        },
      },
    },
    {
      id: 72,
      note: 'Возврат средств инвестору',
      template: 'cashCashBox',
      fields: 'date_doc,operation_type,investor_id,object_id,cashbox_id,value,status,files,filesAdd,filesRemove,comments,operation_name',
      fieldsRO: 'operation_type',
      fieldsForm: 'date_doc,investor_id,object_id,cashbox_id#6,value#6,operation_name,comments,files',
    },

    {
      id: 71,
      note: 'Возврат средств клиенту',
      template: 'cashParent',
      fieldsReplace: {
        parent_id: {
          name: 'parent_id',
          title: 'Основание для возврата',
          type: 'selectapi',
          itemField: { value: 'id', text: 'name' },
          apiVar: { key: 'parent_name', apiName: [{ value: 'ObjectModel', name: 'mechti/objects', preText: 'Объект:' }] },
          validator: ['req'],
        },
        client_id: { name: 'client_id', title: 'Клиент', type: 'selectapi', api: 'mechti/clients/select', validator: ['req'] },
        service_id: { name: 'service_id', title: 'Услуга по договору', type: 'select', dir: 'service', dirFilter: { category_id: 44 } },
      },
      fields: 'date_doc,operation_type,parent_id,cashbox_id,client_id,value,status,note,files,filesAdd,filesRemove,service_id,operation_name',
      fieldsRO: 'operation_type,parent_id,client_id',
      fieldsForm: 'date_doc,parent_id#6,client_id#6,service_id,cashbox_id#6,value#6,operation_name,note,files',
      document: {
        field: 'parent_id',
        model: 'objects',
        api: 'mechti/objects',
        filter: { is_investor: 0 },
        accDocName: 'ObjectModel',
        docFields: 'id,name,client_id',
        fieldsConfig: {
          client_id: { type: 'field', value: 'client_id' },
          object_id: { type: 'field', value: 'id' },
        },
      },
    },
  ],
};
export default cashOut;
