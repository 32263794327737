/** @format */

const interest = [
  { value: 1, text: 'Покупка' },
  { value: 2, text: 'Инвестирование' },
  { value: 3, text: 'Другое' },
  { value: 4, text: 'Дизайн' },
  { value: 5, text: 'Мебель' },
  { value: 6, text: 'Ремонт' },
];

const leads = {
  title: 'Лиды',
  accessName: 'leads',
  api: '/mechti/leads',
  statuses: 'config.statuses.leads',
  groups: [
    { value: 0, text: 'Входящие' },
    { value: 1, text: 'Продажа' },
    { value: 2, text: 'Дизайн и Ремонт' },
    //{ id: 3, name: 'Мебель', value: 3, text: 'Мебель' },
    //{ id: 4, name: 'Ремонт', value: 4, text: 'Ремонт' },
  ],
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    {
      name: 'createdby_id',
      title: 'Автор',
      type: 'select',
      dir: 'user',
      filter: { name: 'createdby_id', type: 'selectapi', api: '/manager/users/select' },
      sortable: true,
      searchable: false,
      width: 150,
    },
    { name: 'name', title: 'ФИО', type: 'string', sortable: true, width: 250 },
    { name: 'actions', title: '', width: 40 },

    { name: 'source', title: 'Источник', type: 'string', sortable: true, width: 80 },
    { name: 'phone1', title: 'Телефон', type: 'string', sortable: true, width: 150 },
    { name: 'interest', title: 'Интересует', type: 'select', options: interest, sortable: true, width: 150 },
    { name: 'type', title: 'Тип', type: 'select', sourceList: 'groups', sortable: true },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'config.statuses.leads', sortable: true },
    { name: 'user_id', title: 'Назначено', type: 'select', dir: 'user' },
    { name: 'createdon', title: 'Дата создания', type: 'datetime', sortable: true, width: 125 },
  ],
  form: [
    { name: 'name', title: 'ФИО', type: 'string', validator: ['req'], autofocus: true },
    { name: 'interest', title: 'Интересует', type: 'select', options: interest },
    { name: 'phone1', title: 'Телефон', type: 'phone' },
    { name: 'phone2', title: 'Телефон 2', type: 'phone' },
    { name: 'whatsapp', title: 'WhatsApp', type: 'string' },
    { name: 'status', title: 'Статус', validator: ['req'], default: 0 },
    { name: 'type', title: 'Тип', type: 'select', sourceList: 'groups', default: 0 },
    { name: 'email', title: 'Email', type: 'string', validator: ['email'] },
    { name: 'user_id', title: 'Назначено', type: 'selectuser', validator: ['req1'], api: { filters: { role: 30 } }, activeFormula_: '(d)=>{return d.role==30}' },
    { name: 'notes', title: 'Заметки', type: 'text' },
    { name: 'source' },
  ],
  viewForm1: {
    fields: 'type,name,source,interest,notes',
  },
  viewForm2: {
    fields: 'phone1,phone2,whatsapp,email',
  },
  config: {
    default: {
      title: 'Лид',
      fields: 'name,interest,phone1,phone2,whatsapp,email,notes,status,user_id,type',
      fieldsRO: '',
      fieldsForm: 'name,interest#3,phone1#6,phone2#6,whatsapp#6,email#6,notes,user_id',
    },
    broker: {
      title: 'клиент',
      fields: 'name,interest,phone1,phone2,whatsapp,email,notes,source,status,user_id,type',
      fieldsRO: '',
      fieldsForm: 'name,interest#6,phone1#6,email,notes,user_id',
      fieldsReplace: {
        source: { name: 'source', title: 'Источник', type: 'string', default: 'broker' },
        phone1: {
          name: 'phone1',
          title: 'Телефон',
          type: 'phone',
          validator: ['req', 'tel2', 'unique'],
          mask: '7**********',
          table: 'leads',
          configUnique: { where: ' and source="broker"' },
          errorText: 'Этот клиент уже зарегистрирован в системе',
        },
        notes: { name: 'notes', title: 'Заметки', type: 'text', placeholder: 'Какой материал вы отправили. Информация для отдела продаж' },
      },
    },
    manager: {
      title: 'Лид',
      fields: 'name,interest,phone1,phone2,whatsapp,email,notes,source,status,user_id,type',
      fieldsRO: 'source',
      fieldsForm: 'name,interest#6,phone1#6,email,notes,user_id',
      fieldsReplace: {
        phone1: {
          name: 'phone1',
          title: 'Телефон',
          type: 'phone',
          validator: ['req', 'tel2', '@unique'],
          mask: '7**********',
          table: 'leads',
          configUnique: { where: ' and source="broker"' },
          errorText: 'Этот клиент уже зарегистрирован в системе',
        },
        notes: { name: 'notes', title: 'Заметки', type: 'text', placeholder: 'Какой материал вы отправили. Информация для отдела продаж' },
      },
    },
  },
  actions: {
    title: 'Действия',
    accessName: 'leads',
    api: '/mechti/leads_action',
    statuses: 'config.statuses.lead_actions',
    lead_actions: [
      { value: 1, text: 'Связаться', action_text: 'Совершен звонок', status_lead1: '(d)=>{return !d.status ? 1 : d.status}' },
      { value: 2, text: 'Отправить КП', action_text: 'Отправлено КП', status_lead1: '(d)=>{return 2}' },
      { value: 3, text: 'Назначить показ', action_text: 'Назначен показ', status_lead1: '(d)=>{return 3}' },
      { value: 4, text: 'Провести показ', action_text: 'Проведен показ', status_lead1: '(d)=>{return 4}' },
      { value: 5, text: 'Заключить договор', action_text: 'Заключен договор', status_lead1: '(d)=>{return 100}' },
    ],
    list: [
      { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
      { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', sortable: true, searchable: false, width: 150 },
      { name: 'createdon', title: 'Дата создания', type: 'datetime', format: ['shortTime'], sortable: true, width: 120 },
      { name: 'type', title: 'Действие', type: 'select', options: 'lead_actions', textField: 'action_text', sortable: true, width: 80 },
      { name: 'comment', title: 'Комментарий', type: 'text' },
    ],
    form: [
      {
        name: 'type',
        title: 'Что было сделано',
        type: 'select',
        options: 'lead_actions',
        textField: 'action_text',
        validator: ['req'],
        cascade: {
          status: { type: 'formula', value: 'status_lead' },
        },
      },
      { name: 'comment', title: 'Комментарий', type: 'text' },
      { name: 'status', default: undefined },
      {
        name: 'tasks_data',
        title: 'Создать следующую задачу?',
        type: 'table',
        modelConfig1: 'leads.tasks',
        model: {
          title: 'Задача',
          form: [
            { name: 'type', title: 'Что надо сделать', type: 'select', options: 'lead_actions', validator: ['req'], width: 120 },
            { name: 'eventon', title: 'Дата', type: 'datetimestring', validator: ['req'], width: 120 },
            { name: 'comment', title: 'Комментарий', type: 'text', width: 400 },
            { name: 'status', default: 1, hidden: true },
          ],
          fieldsForm: 'type#8,eventon#4,comment',
        },
      },
      { name: 'lead_id', validator: ['req'] },
    ],
    config: {
      default: {
        title: 'Действие',
        fields: 'type,comment,status,lead_id,tasks_data',
        fieldsRO: '',
        fieldsForm: 'type,comment,tasks_data',
        watch1: {
          type1: { status: { type: 'field', value: 'client_id' } },
        },
      },
      done: {
        title: 'Действие - результат выполнения задачи',
        fields: 'type,comment,status,lead_id,tasks_data',
        fieldsRO: 'type',
        fieldsPost: 'type,comment,lead_id,status,tasks_data,task_id',
        fieldsForm: 'type,comment,tasks_data',
      },
    },
  },
  tasks: {
    title: 'Задачи',
    accessName: 'leads',
    api: '/mechti/leads_task',
    statuses: 'config.statuses.lead_actions',
    lead_actions: [
      { value: 1, text: 'Связаться', action_text: 'Совершен звонок', status_lead1: '(d)=>{return !d.status ? 1 : d.status}' },
      { value: 2, text: 'Отправить КП', action_text: 'Отправлено КП', status_lead1: '(d)=>{return 2}' },
      { value: 3, text: 'Назначить показ', action_text: 'Назначен показ', status_lead1: '(d)=>{return 3}' },
      { value: 4, text: 'Провести показ', action_text: 'Проведен показ', status_lead1: '(d)=>{return 4}' },
      { value: 5, text: 'Заключить договор', action_text: 'Заключен договор', status_lead1: '(d)=>{return 100}' },
    ],
    list: [
      { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
      { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user', sortable: true, searchable: false, width: 150 },
      { name: 'createdon', title: 'Дата создания', type: 'datetime', format: ['shortTime'], sortable: true, width: 120 },
      { name: 'type', title: 'Действие', type: 'select', options: 'lead_actions', sortable: true, width: 80 },
      { name: 'eventon', title: 'Дата', type: 'datetime', format: ['shortTime'], sortable: true, width: 120 },
      { name: 'comment', title: 'Комментарий', type: 'text' },
      { name: 'actions', title: '', width: 40 },
      { name: 'status', title: 'Статус', type: 'select', options: 'status_active' },
    ],
    form: [
      { name: 'type', title: 'Что надо сделать', type: 'select', options: 'lead_actions', validator: ['req'] },
      { name: 'eventon', title: 'Дата', type: 'datetimestring', validator: ['req'] },
      { name: 'comment', title: 'Комментарий для задачи', type: 'text', rows: 2 },
      { name: 'status', default: 1 },
      { name: 'lead_id', validator: ['req'] },
    ],
    config: {
      default: {
        title: 'Задача',
        fields: 'type,eventon,comment,status,lead_id',
        fieldsRO: '',
        fieldsForm: 'type#8,eventon#4,comment',
      },
    },
  },
};

export default leads;
