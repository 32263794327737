<template>
  <div class="d-flex" style="position: relative">
    <v-text-field
      outlined
      :value="value"
      :label="model.title"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :readonly="readonly"
      :dense="config.dense || false"
      @input="$emit('input', $event)"
      :class="classBlock"
      :autofocus="model.autofocus || false"
    >
    </v-text-field>
    <btn-rating v-if="(model.rating && config.rating) || false" v-model="rating"> </btn-rating>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    classBlock() {
      if (this.model?.class) return this.model.class;
      return this.config?.class || "";
    },
    rating: {
      get() {
        let res = this.values.data?.rating?.[this.model.name] || "";
        return res;
      },
      set(v) {
        this.$emit("rating", { name: this.model.name, value: v });
      },
    },
  },
};
</script>
